import { Box, Button, Typography } from "@mui/material";
import {useState, useEffect}  from "react";
import SellForm from "./sellForm";
import BuyForm from "./buyForm";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useBoolean } from "../../../../utils/hooks/use-boolean";


function Transactions ({onClose, ...props}){
    const [transaction, setTransaction] = useState();
    let res = <div className="w-full h-full mt-4">
    <Typography textAlign='center' variant="h4">
        איזו פעולה תרצו לבצע?
    </Typography>
         <Box className="h-full items-center justify-evenly mt-24 w-full flex " component='div'>
                    <Button 
    
                    variant="outlined" color="primary" 
                    onClick={()=>{setTransaction(2)}} sx={{p:{xs:4, sm:6}, borderRadius:4, boxShadow:8, display:'block' }}>
                        פעולת קנייה
                        <Icon className="mx-auto" icon="material-symbols-light:point-of-sale" width={30} />
                    </Button>
                    <Button variant="outlined" color="primary" 
                    onClick={()=>{setTransaction(1)}} 
                    sx={{p:{xs:4, sm:6}, borderRadius:4, boxShadow:8, display:'block' }}>
                        פעולת מכירה
                        <Icon className="mx-auto" icon="solar:bag-2-bold-duotone" width={30} />
                    </Button>
                </Box>
    </div>
    if (transaction === 1 ){
        res = <SellForm onClose={onClose} showBackArrow callBack={()=>setTransaction(undefined)} />
    }
    else if ( transaction === 2){
        res = <BuyForm onClose={onClose} showBackArrow callBack={()=>setTransaction(undefined)} />
    }

    return (
     res
    );
}

export default Transactions;