import {useState, useEffect, useRef}  from "react";
import './inventory.css';
import '../../../styles.css';
import { useBoolean } from "../../../utils/hooks/use-boolean";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CircularProgress, Dialog, DialogContent, FormHelperText, Tooltip } from "@mui/material";
import { Box, TextField, Button, Grid, Typography } from "@mui/material";
import ItemCard from "./itemCard";
import { products } from "./products";
import HoverButton, { gradients } from "./floatingButton/hoverButton";
import NewItem from "./newItem/NewItem";
import { grey } from "@mui/material/colors";
import { useAuth, useStock } from "../../../App";
import axios from "axios";
import KeyValueTable from "./dbTable/renderTable";
import { useInView, motion } from "framer-motion";
import ProductForm from "./transactions/sellForm";
import Transactions from "./transactions/transactionMain";
import ItemsTable from "./dbTable/renderTable";
import ScanBarcodeSlideButton from "../../assets/scanBarcode";
import ChatWidget from "../../chatWidget/chatWidget";
import MainAnalysis from "./dataBox/showData";
import VideoWithBlur from "../../campainVideo/videoWithBlur";




function Inventory ({...props}){
    return (
        <div className="flex justify-center pt-14 relative my-8 overflow-x-hidden">
           
            
            {/* <MainView /> */}
            <HomeScreen products={products} />
            
        </div>
    );
}

export default Inventory;


const buttonsOptions = [
  { name: 'תנועות', value: 'transactions' },
  { name: 'קטגוריות', value: 'category' },
  { name: 'נוספו לאחרונה', value: 'recentlyAdded' },
  { name: 'מלאי', value: 'stock' },
];

const MAX_TABLE_LENGTH = 200;


const HomeScreen = ({ products }) => {
  const [search, setSearch] = useState("");
  const [cardMode, setCardMode] = useState(0);
  const [option, setOption] = useState('main');
  const [detectedItem, setdetectedItem] = useState({});
  const {stock, setStock} = useStock();
  const {serverIP} = useAuth();
  const addNewItem = useBoolean(false);
  const setTransaction = useBoolean(false);
  const isLoading = useBoolean(true);
  const showImage = useBoolean(true);
  const showCard = useBoolean(true);
  const scrollRef = useRef(null);
  const inView = useInView(scrollRef, {amount: 0.04});

  const updateDataBase = ()=>{
    isLoading.onTrue();
    setTimeout(()=>{
      isLoading.onFalse()
    },3*1e3);
    axios.get(serverIP +  '/products')
    .then(res=>{
      setStock(res.data?.slice(0,MAX_TABLE_LENGTH));
      // console.log('This is server data: ', res.data[0])
      isLoading.onFalse();
    }).catch(err=> console.error('There was an error: ', err));
  }

  const handleDetectedItem = (item={})=>{
    setdetectedItem(item);
    addNewItem.onTrue();
  }

  useEffect(()=>{
    if (!setTransaction.value && !addNewItem.value){
      setTimeout(()=>{
        updateDataBase();
      }, 0.5*1e3)
      setdetectedItem({});
    }

  },[addNewItem.value, setTransaction.value])


  // useEffect(()=>{
  //   axios.get(serverIP +  '/products')
  //   .then(res=>{
  //     setStock(res.data?.slice(0,MAX_TABLE_LENGTH));
  //     console.log('This is server data: ', res.data[0])
  //   }).catch(err=> console.error('There was an error: ', err));
  // },[])

  useEffect(()=>{
    if (!search){
      return
    }
    isLoading.onTrue();
    setTimeout(()=>{
      isLoading.onFalse();
    },3*1e3)
    axios.post(serverIP +  '/search_products',{query: search})
    .then(res=>{
      // setStock(res.data);

      if (res.status === 200){
       
        setStock(res.data.data);
       
        isLoading.onFalse();
      }
      console.log('This is server data: ', res.data)
    }).catch(err=> console.error('There was an error: ', err));
  },[search])

  useEffect(()=>{
    isLoading.onTrue();
    setTimeout(()=>{
      isLoading.onFalse();
    }, 2*1e3)
  },[cardMode])

  useEffect(()=>{
    if (inView){
      document.getElementById('hoverButtons').classList.add('scale-75','!left-0')
    } else {
      document.getElementById('hoverButtons').classList.remove('scale-75','!left-0') 
    }
  },[inView])
  
  // const filteredDataBase = stock?.filter((product, index) =>
  // (product.category?.toLowerCase().includes(search?.toLowerCase()) ||
  // product.product_name?.toLowerCase().includes(search?.toLowerCase()) ||
  // product.sku?.toLowerCase().includes(search?.toLowerCase()) ) && index < MAX_TABLE_LENGTH

  // );


  const filteredDataBase = stock;

  // Fixed buttons for: add item, sell/buy items, edit inventory
  const hoverButtons = <div 
        id='hoverButtons'
        className="fixed transition-transform max-sm:relative max-sm:w-full text-zinc-900 z-50 flex flex-col max-sm:flex-row justify-center gap-2 max-sm:inset-0 max-sm:z-10 max-sm:mb-4 left-1 top-1/3">
          <div className="w-full justify-end max-sm:justify-center flex">
              <HoverButton seed={4} onClick={addNewItem.onToggle} buttonHover={'יצירת פריט חדש'} >
                  <Icon icon="typcn:plus" color="black" width={20} height={20} />
              </HoverButton>
          </div>
          <div className="w-full justify-end max-sm:justify-center flex">
              <HoverButton seed={8} onClick={setTransaction.onToggle} buttonHover={'פעולות קנייה/מכירה'} >
                  <Icon icon="icon-park-outline:buy" color="black" width={20} height={20} />
              </HoverButton>
          </div>
          <div className="w-full justify-end max-sm:justify-center flex">
              <HoverButton seed={2} buttonHover={'ניהול מלאי'} >
                  <Icon icon="material-symbols:inventory" color="black" width={20} height={20} />
              </HoverButton>
          </div>
          {/* ד<ScanDigits /> */}
          <ScanBarcodeSlideButton onDetect={handleDetectedItem} />
      </div>

  const layoutButtons = <div className="flex w-full gap-4 mb-4">
    <Tooltip arrow placement="top-end" title='טבלה'>
      <Icon style={{opacity:cardMode===0 ? 1: 0.3}} className="hover:bg-gray-200/50 cursor-pointer p-1 rounded-lg hover:shadow-xl"
        onClick={()=>setCardMode(0)}
        icon="material-symbols-light:table" width={30}/>
    </Tooltip>
    <Tooltip arrow placement="top-end" title='פריסה רגילה'>
      <Icon style={{opacity:cardMode===1 ? 1: 0.3}} className="hover:bg-gray-200/50 cursor-pointer p-1 rounded-lg hover:shadow-xl"
        onClick={()=>setCardMode(1)}
        icon="bxs:layout" width={30}/>
    </Tooltip>
    <Tooltip arrow placement="top-end" title='פריסה צרה'>
      <Icon style={{opacity:cardMode===2 ? 1: 0.3}} className="hover:bg-gray-200/50 cursor-pointer p-1 rounded-lg hover:shadow-xl"
        onClick={()=>setCardMode(2)}
        icon="mingcute:layout-9-fill" width={30}/>
    </Tooltip>
    <Tooltip arrow placement="top-end" title='פריסה רחבה'>
    <Icon style={{opacity:cardMode===3 ? 1: 0.3}} className="hover:bg-gray-200/50 cursor-pointer p-1 rounded-lg hover:shadow-xl"
      onClick={()=>setCardMode(3)}
      icon="tabler:layout-list-filled" width={30}/>
    </Tooltip>
    <Tooltip arrow placement="top-end" title='הצג תמונה'>
      <Icon style={{opacity:showImage.value ? 1: 0.3}} className="hover:bg-gray-200/50 cursor-pointer p-1 rounded-lg hover:shadow-xl"
        onClick={showImage.onToggle}
        icon="mi:image" width={30}/>
    </Tooltip>
    {cardMode !== 0 && 
     <Tooltip arrow placement="top-end" title='מסגרת'>
      <Icon style={{opacity:showCard.value ? 1: 0.3}} className="hover:bg-gray-200/50 cursor-pointer p-1 rounded-lg hover:shadow-xl"
        onClick={showCard.onToggle}
        icon="solar:card-2-bold" width={30}/>
    </Tooltip>}
    <Tooltip arrow placement="top-end" title='רענון'>
      <Icon className="hover:bg-gray-200/50 cursor-pointer p-1 max-sm:hidden rounded-lg hover:shadow-xl"
        onClick={updateDataBase}
        icon="ic:outline-refresh" width={30}/>
    </Tooltip>
  </div>

  const optionButtons =   
        <Box width={1} sx={{ display: "flex", justifyContent:'center', gap: 2, my: 4, flexWrap:'wrap' }}>
          <Button className={`${gradients[4]} hover:bg-gradient-to-l`} onClick={addNewItem.onTrue} sx={{borderRadius: 4, fontSize:10}} startIcon={<Icon className="ml-2" icon="typcn:plus"/>} color="error" variant="contained">פריט חדש</Button>
          {buttonsOptions.map(({ name, value }) => (
            <Button
              key={value}
              onClick={() => setOption(p => (p === value ? undefined : value))}
              sx={{ borderRadius: 4, fontSize: 10 }}
              variant={option === value ? 'contained' : 'outlined'}
            >
              {name}
            </Button>
          ))}
              </Box>
  // Form to add new item to Inventory DB
  const newItem = 
    <Dialog 
      className="!bg-gray-300/5 relative"
      PaperProps={{className:'!bg-gradient-to-br from-white !rounded-xl to-gray-200/40 dark:from-darkGrey dark:via-darkGrey dark:to-zinc-950 !min-w-[60%] max-sm:!min-w-[95%] max-sm:!min-h-[95%] !min-h-[80vh]'}}
      fullWidth 
      // fullScreen={isMobile}
      open={addNewItem.value} onClose={addNewItem.onFalse}>

      <CloseIcon onClick={(e)=>{e.stopPropagation(); addNewItem.onFalse()}} />
        <DialogContent sx={{px:0}} className="scrollDiv">
          <NewItem newItemData={detectedItem} onClose={addNewItem.onFalse} saveData={!addNewItem.value} />
        </DialogContent>
    </Dialog>

  const newTransaction = 
    <Dialog 
      className="!bg-gray-300/5 relative"
      PaperProps={{className:'!bg-gradient-to-br from-white !rounded-xl to-gray-200/40 dark:from-darkGrey dark:via-darkGrey dark:to-zinc-950 !min-w-[60%] max-sm:!min-w-[95%] max-sm:!min-h-[95%] !min-h-[80vh]'}}
      fullWidth 
      // fullScreen={isMobile}
      open={setTransaction.value} onClose={setTransaction.onFalse}>

      <CloseIcon onClick={(e)=>{e.stopPropagation(); setTransaction.onFalse()}} />
        <DialogContent sx={{px:0}} className="scrollDiv">
          <Transactions onClose={setTransaction.onFalse} />
        </DialogContent>
    </Dialog>

  const analysis =  <div className="w-[90%] mx-auto flex-wrap flex gap-8">
            <div className="p-20 max-sm:p-10 cursor-pointer hover:bg-slate-900 hover:text-white hover:shadow-lg rounded-xl bg-teal-500/30 border-[1px] flex-1 border-zinc-400/20">נתונים 1</div>
            <div className="p-20 max-sm:p-10 cursor-pointer hover:bg-slate-900 hover:text-white hover:shadow-lg rounded-xl bg-teal-500/30 border-[1px] flex-1 border-zinc-400/20">נתונים 2</div>
            <div className="p-20 max-sm:p-10 cursor-pointer hover:bg-slate-900 hover:text-white hover:shadow-lg rounded-xl bg-teal-500/30 border-[1px] flex-1 border-zinc-400/20">נתונים 3</div>
          </div>

let res;
switch (option){
  case 'transactions':
    axios.post(serverIP + '/get_all_transactions', {time_duration: 'month'})
    .then(res=> console.log(res.data))
    res = <Typography gutterBottom variant="h3">
      תנועות
    </Typography>
  break;
  case 'category':
    res = <Typography gutterBottom variant="h3"> 
    קטגוריות
  </Typography>
  break;
  case 'recentlyAdded':
    res = <Typography gutterBottom variant="h3">
    נוספו לאחרונה
  </Typography>
  break;
  case 'stock':
        res = <ShowStock />
  break;
  default:
  res = <>
     {/* {analysis} */}
     <MainAnalysis sales={'7,728₪'} revenue={'13,389₪'} insights={'יש 4 מוצרים שאינם בשימוש כבר חודשיים'} />
    <Box my={4} width={1}>
      <div className="relative right-8">
        {layoutButtons}
      </div>
      <div 
      className="flex gap-2 mx-auto w-[95%] max-sm:w-full flex-wrap relative justify-center">
      {isLoading.value ? <CircularProgress size={40} />:
         cardMode === 0 ? <ItemsTable editable onItemUpdate={updateDataBase} showImage={showImage.value} showButtons data={filteredDataBase} /> :
          filteredDataBase.map((product, index) => (
            <div  className={cardMode === 1 ? 'w-auto flex justify-center max-sm:w-[95%]': cardMode ===2 ? "w-auto max-w-[300px] flex justify-center max-sm:w-[95%]": "w-auto flex justify-center max-sm:w-[95%]"}  key={index}>
                <ItemCard showAllData mode={cardMode} showImage={showImage.value} showCard={showCard.value} product={product} showExtra={true} />
            </div>
        ))}
      </div>
    </Box>
  </>
  break;

}

  return (
    <Box position='relative' mb={4} width={1} sx={{ px: {xs:1, sm:2}, textAlign:'center' }}>
    <ChatWidget />
    {/* <VideoWithBlur /> */}
    {newItem}
    {newTransaction}
    
      {/* <Typography mb={2} variant="h4" gutterBottom>
        ניהול מלאי
      </Typography> */}
      <Box sx={{ display: "flex", gap: 2, mb:4, direction:'rtl', justifyContent:'center' }}>
        <div className="w-full">
            <input     
            dir="auto"
            inputMode="text"
            className="mainInput bg-gradient-to-l text-center border-[0.8px] border-zinc-400/200 from-white to-blue-100/30 shadow-md focus-visible:ring-main dark:bg-gradient-to-r dark:from-zinc-800/90 dark:to-slate-950/80 w-[80%] max-w-[800px] min-w-[250px] h-12 !rounded-full"
            placeholder="חפש או סרוק מוצר"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            />        
        </div>
      </Box>
      {hoverButtons}
      {optionButtons}
      <div ref={scrollRef} className="w-full">
      {res}
      </div>
    </Box>
  );
};



export const CloseIcon = ({ top=-1, right=-1,size=34, bottom, left,title,className,onClick=()=>{}})=> (
  <Tooltip arrow title={title || 'סגירה'}>
    <Icon 
          style={{top, right, left, bottom, cursor:'pointer'}}
          onClick={onClick}
          className={className || 'absolute p-1 z-10 rounded-full cursor-pointer hover:shadow-2xl hover:opacity-70 '}
          icon="icon-park-solid:close-one" width={size} height={size} />
  </Tooltip>
)


const ShowStock = ()=>{
  const {serverIP} = useAuth();
  const [stock, setStock] = useState();
  useEffect(()=>{
    axios.get(serverIP + '/get_product_stock_summary')
    .then(res => {
      if (res.status === 200){
        // console.log('This is server getStock: ', res.data);
        setStock(res.data);
      }
    })
    .catch(err => console.error('Error with get_product_stock_summary', err))
  },[])
    const stockThreshold = 8;
  
    return (
      <div>
        <Typography variant="h3">רשימת מלאי</Typography>
        {stockThreshold && 
        <Typography color="text.secondary" gutterBottom variant="body1">פריט שהמלאי שלו קטן מ {stockThreshold} מודגש באדום</Typography>}
        <ItemsTable stockThreshold={stockThreshold} showButtons orderList={['sku','quantity', 'product_name']} data={stock} />
      </div>
    );
}