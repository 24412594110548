export const products = [
    {
      sku: 1,
      serial: "ABC123",
      name: "Yoga Mat",
      category: "Fitness",
      company: "FitPro",
      description: "A high-quality yoga mat with excellent grip.",
      color: "Blue",
      size: "Standard",
      price: 20,
      cost: 10,
      image: "https://picsum.photos/200/300?random=1",
    },
    {
      sku: 2,
      serial: "DEF456",
      name: "Dumbbell Set",
      category: "Gym Equipment",
      company: "StrongFit",
      description: "A pair of adjustable dumbbells for strength training.",
      color: "Black",
      size: "Variable",
      price: 50,
      cost: 30,
      image: "https://picsum.photos/200/300?random=2",
    },
    {
      sku: 3,
      serial: "GHI789",
      name: "Resistance Bands",
      category: "Fitness",
      company: "ActiveLife",
      description: "Set of resistance bands with varying strengths.",
      color: "Multicolor",
      size: "Standard",
      price: 15,
      cost: 7,
      image: "https://picsum.photos/200/300?random=3",
    },
    {
      sku: 4,
      serial: "ABC123",
      name: "Yoga Mat",
      category: "Fitness",
      company: "FitPro",
      description: "A high-quality yoga mat with excellent grip.",
      color: "Blue",
      size: "Standard",
      price: 20,
      cost: 10,
      image: "https://picsum.photos/200/300?random=1",
    },
    {
      sku: 5,
      serial: "DEF456",
      name: "Dumbbell Set",
      category: "Gym Equipment",
      company: "StrongFit",
      description: "A pair of adjustable dumbbells for strength training.",
      color: "Black",
      size: "Variable",
      price: 50,
      cost: 30,
      image: "https://picsum.photos/200/300?random=2",
    },
    {
      sku: 6,
      serial: "GHI789",
      name: "Resistance Bands",
      category: "Fitness",
      company: "ActiveLife",
      description: "Set of resistance bands with varying strengths.",
      color: "Multicolor",
      size: "Standard",
      price: 15,
      cost: 7,
      image: "https://picsum.photos/200/300?random=3",
    }
    // Add more objects here...
  ];
  