import { Icon } from '@iconify/react/dist/iconify.js';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton, Box } from '@mui/material';

import React from 'react';




export const GroupsTable = ({ groups, onClick = () => {} }) => {
    return (
        <Box sx={{ px: 4, mb: 8, mt: 4,minWidth:0.4,  maxWidth: 0.98, width:'fit-content' }}>
            <TableContainer dir='rtl'>
                <Table>
                    <TableHead>
                        <TableRow className='bg-gradient-to-r from-zinc-200 to-slate-100 dark:bg-gray-800 dark:from-transparent dark:bg-gradient-to-l dark:to-slate-900'>
                            <TableCell sx={{ textAlign: 'center' }}>ID</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>מזהה קבוצה</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>שם קבוצה</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups.map((group, index) => (
                            <TableRow
                                onClick={() => onClick(group, index)}
                                className='hover:bg-zinc-100 dark:hover:bg-zinc-700 cursor-pointer'
                                key={index}
                            >
                                <TableCell>{group.id}</TableCell>
                                <TableCell>{group.groupid || group.groupId}</TableCell>
                                <TableCell>{group.groupname || group.groupName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default GroupsTable;
