import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode,Html5QrcodeScanner , Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { useBoolean } from '../../utils/hooks/use-boolean';
import {motion} from 'framer-motion';
import { CloseIcon } from '../services/InventoryAI/InventoryMain';
import HoverButton from '../services/InventoryAI/floatingButton/hoverButton';
import { Icon } from '@iconify/react/dist/iconify.js';
import { CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../../App';

export function ScanBarcode({showCamera ,onDetect=()=>{}, onClose=()=>{}}) {
  const videoRef = useRef(null);
  const {serverIP} = useAuth();
  const html5QrcodeScanner = useRef(null);

  useEffect(() => {
    if (showCamera) {
      const config = {
        fps: 10,
        qrbox: { width: 400, height: 250 }, // Scanner viewport dimensions
        formatsToSupport: [
          Html5QrcodeSupportedFormats.QR_CODE,
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.CODE_39,
          Html5QrcodeSupportedFormats.EAN_13,
          Html5QrcodeSupportedFormats.EAN_8,
          Html5QrcodeSupportedFormats.UPC_A,
          Html5QrcodeSupportedFormats.UPC_E,
          Html5QrcodeSupportedFormats.ITF,
          Html5QrcodeSupportedFormats.PDF_417,
          Html5QrcodeSupportedFormats.AZTEC,
          Html5QrcodeSupportedFormats.DATA_MATRIX,
        ],
        experimentalFeatures: {
          useBarCodeDetectorIfSupported: true, // Improve browser compatibility
        },
        verbose: true,
      };

      // const scanner = new Html5QrcodeScanner(
      //   videoRef.current.id,
      //   config,
      //   false
      // );

      // scanner.render(
      //   (decodedText) => {
      //     console.log('Barcode detected:', decodedText);
      //     stopCamera(); // Automatically stop scanning after detection
      //     handleBarcode(decodedText)
      //   },
      //   (errorMessage) => {
      //     console.error('Error scanning barcode:', errorMessage);
      //   }
      // );

      html5QrcodeScanner.current = new Html5Qrcode(videoRef.current.id);

      html5QrcodeScanner.current
        .start(
          { facingMode: 'environment' }, // Use back camera
          config,
          (decodedText) => {
            console.log('Barcode detected:', decodedText);
            stopCamera();
            handleBarcode(decodedText);
          },
          (errorMessage) => {
            console.warn('Decode error:', errorMessage);
          }
        )
        .catch((err) => {
          console.error('Error initializing scanner:', err);
        });

      return () => {
        // if (scanner){
        //     scanner.clear();
        // }
        if (html5QrcodeScanner.current?.isScanning){
         html5QrcodeScanner.current.stop().catch((err) => console.error(err));
        }
      };
    }
  }, [showCamera]);

  const handleBarcode = async (barcode)=>{
    try{
        const res = await axios.post(serverIP +  '/search_products',{query: barcode});
        const items = res.data?.data;
        if (items && items?.length === 1 && items[0].sku){
            const item = items[0]
            console.log('Detected item: ', item);
            onDetect({...item, barcode});
        }
        else{
            onDetect({barcode});
        }
    } catch(err){
        console.error('Error with barcode searching: ', err)
    }

  }
  const stopCamera = () => {
    if (html5QrcodeScanner.current?.isScanning) {
      html5QrcodeScanner.current
        .stop()
        .then(() => html5QrcodeScanner.current?.clear())
        .catch((err) => console.error('Error stopping scanner:', err));
    }
    onClose();
  };


  return (
    <>
      {showCamera && (
        <motion.div 
        initial={{opacity:0, translateY:-100}}
        animate={{opacity:1, translateY:0}}
        transition={{duration: 0.5}}
        className="right-1/3 z-[99] border-[1px] border-zinc-400/20 rounded-lg shadow-lg fixed max-sm:right-[15%] top-1/4">
          <CloseIcon onClick={stopCamera} />
          <div ref={videoRef} id="scanner-container" className="bg-white rounded-lg rounded-b-none w-[400px] max-sm:w-[70vw]" />
        </motion.div>
      )}
    </>
  );
}


const ScanBarcodeSlideButton = ({onDetect=()=>{}})=>{
  const showCamera = useBoolean(false);
  
  const handleScanClick = (e) => {
    e.stopPropagation();
    showCamera.onTrue();
  };

  return (
    <>
      <ScanBarcode showCamera={showCamera.value} onClose={showCamera.onFalse} onDetect={onDetect} />
      <div className="w-full justify-end max-sm:justify-center flex">
        <HoverButton onClick={handleScanClick} seed={9} buttonHover="סרוק ברקוד">
          <Icon icon="mdi:qrcode-scan" width={20} height={20} />
        </HoverButton>
      </div>
    </>
  );

}

export default ScanBarcodeSlideButton;
