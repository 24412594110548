import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useAuth } from '../../../App';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, hexToRgb, Icon, styled, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses } from '@mui/material';
import { useBoolean } from '../../../utils/hooks/use-boolean';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius:4,
  boxShadow:4,
  background: `rgba(150,150,150,0.1)`,
  border: '1px solid rgba(120,120,120,0.4)',
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: 8,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));



function Settings() {
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState({});
  const [content, setContent] = useState('');
  const [showPromptList, setShowPromptList] = useState(false);
  const [promptMsg, setPromptMsg] = useState(null);
  const showDialog = useBoolean(false);
  const isFetching = useBoolean(false);
  const {serverIP} = useAuth();

  const loadPromptList = () => {
    const newShowPromptList = !showPromptList;
    setShowPromptList(newShowPromptList);
    if (newShowPromptList) {
      axios.get(serverIP + '/get_prompts')
        .then(response => {
          setPrompts(response.data.prompts);
          const {filename, filepath} = response.data?.prompts[0];
          setSelectedPrompt({filename,filepath});
          loadPromptContent(filename,filepath)
        })
        .catch(error => console.error(error));
    }
  };

  const loadPromptContent = (filename, filepath) => {
    axios.post(serverIP +'/get_prompt_content', { filepath })
      .then(response => {
        setSelectedPrompt({ filename, filepath });
        setContent(response.data.content);
      })
      .catch(error => console.error(error));
  };

  const savePrompt = () => {
    isFetching.onTrue();
    axios.post(serverIP +'/save_prompt', {
      prompt: {
        filename: selectedPrompt.filename,
        content
      }
    })
      .then(() => {
        setPromptMsg('הפרומפט נשמר בהצלחה');
        showDialog.onTrue();
        isFetching.onFalse();
      })
      .catch(error => {
        setPromptMsg('שגיאה בשמירת הפרומפט');
        showDialog.onTrue();
        isFetching.onFalse();
        console.error(error);
      });
  };

  const dialog =   
      <Dialog PaperProps={{sx:{borderRadius:2,px:8, py:2, overflow:'visible'}}}  open={showDialog.value} onClose={showDialog.onFalse}>
            <div onClick={showDialog.onFalse} className='absolute p-1 rounded-full cursor-pointer hover:shadow-2xl hover:opacity-80 -top-2 -right-2'>
                 <Icon icon="icon-park-solid:close-one" width="48" height="48" />
            </div>
        
            <DialogTitle> {promptMsg}</DialogTitle>
            {/* <DialogContent>
              <DialogContentText>
                
              </DialogContentText>
            </DialogContent> */}
            <Divider sx={{borderStyle:'dashed', width:'80%' }} />
            <DialogActions>
            <div className='w-full flex justify-center'>
              <button onClick={showDialog.onFalse} className='mainButton flex items-center justify-center w-[50%]'>
                תודה
              </button>
            </div>
            </DialogActions>
      </Dialog>

  return (
    <div className='min-h-[80vh] w-full'>
      <div className='h-20'/>
      <div className='p-4 w-full'>
        <div className='w-full flex justify-center'>
          <button
            className='mainButton'
            onClick={loadPromptList}
          >
            {showPromptList ? 'הסתר פרומפטים' : 'הצג פרומפטים'}
          </button>
        </div>
        {showPromptList && (
          <motion.div 
            animate={{opacity:1, y:0}}
            initial={{opacity:0, y:30}}
            transition={{ duration: 0.8 }} >
          <div className='w-full flex justify-center my-8'>
            <StyledToggleButtonGroup  sx={{ borderRadius: 4, my: 2 , direction:'ltr'}}>
            {prompts.map((prompt, index) => (
              <div key={index}>
                    <ToggleButton
                      sx={{ px: 2 }}
                      onClick={() => {
                          if (prompt?.filename === selectedPrompt?.filename ){
                              setSelectedPrompt({});
                              setContent('');
                              return;
                            }
                            loadPromptContent(prompt.filename, prompt.filepath)
                      }}
                      selected={prompt?.filename === selectedPrompt?.filename}
                    >
                        {prompt.filename}
                    </ToggleButton>
            </div>
            ))}
            </StyledToggleButtonGroup>
          </div>
          {dialog}
       
        {selectedPrompt.filename && (
          <div className='flex text-center justify-center gap-4 flex-col'>
            <div className=''>
              <div className='mb-4 font-bold'>{selectedPrompt?.filename}</div>
              <textarea
                dir='auto'
                className="scrollDiv w-5/6 z-10 min-h-60 mainInput bg-slate-100 dark:bg-slate-950 !shadow-none !hover:shadow-none"
                value={content}
                onChange={e => setContent(e.target.value)}
              />
            </div>
            <div className='flex w-full justify-center'>
              <button className='mainButton w-[20%] min-w-24' onClick={savePrompt}>שמור</button>
            </div>
          </div>
        )}
         </motion.div>)}
      </div>
    </div>
  );
}

export default Settings;
