import React, { useEffect, useRef, useState } from 'react';
import '../../../../styles.css';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  Avatar,
  NoSsr,
  Alert,
  AlertTitle,
  Collapse,
  TextField,
  CircularProgress,

} from '@mui/material';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';

import { getCookie, removeCookie, setCookie, useCookies } from '../../../../utils/hooks/use-cookie';
import { useBoolean } from '../../../../utils/hooks/use-boolean';
import { CloseIcon } from '../InventoryMain';
import imageCompression from 'browser-image-compression';
import { Fieldset } from '@headlessui/react';
import axios from 'axios';
import { useAuth } from '../../../../App';
import AlertDialog from '../../../assets/dialogApp';
import { ScanBarcode } from '../../../assets/scanBarcode';


export const currencyDict = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CNY: '¥',
  ILS: '₪'
};

export const currencies = [
  { value: 2, label: 'US Dollar', symbol: '$', flag: `${process.env.PUBLIC_URL}/assets/countryFlags/us.webp` },
  { value: 3, label: 'Euro', symbol: '€', flag: `${process.env.PUBLIC_URL}/assets/countryFlags/eu.webp` },
  { value: 4, label: 'British Pound Sterling', symbol: '£', flag: `${process.env.PUBLIC_URL}/assets/countryFlags/gb.webp` },
  // { value: 6, label: 'Japanese Yen', symbol: '¥', flag: `${process.env.PUBLIC_URL}/assets/countryFlags/jp.webp` },
  { value: 5, label: 'Chinese Yuan', symbol: '¥', flag: `${process.env.PUBLIC_URL}/assets/countryFlags/ch.webp` },
  { value: 1, label: 'Israeli New Shekel', symbol: '₪', flag: `${process.env.PUBLIC_URL}/assets/countryFlags/il.webp` },
];

const defaultValues = {
  sku:'',
  product_name:'',
  description:'',
  category: '',
  id:'',
  buy_currency:1,
  sell_currency:1,

}



export const variantName = 'standard';
const MAX_STOCK_COUNT = 100000;

const MAX_CUSTOM_FIELD = 50;
const buttonClass = 'rounded-full text-slate-900 p-2 bg-gradient-to-r from-gray-100 to-gray-300';

export default function NewItem({saveData, onClose, newItemData={}}) {
  const clientNewItem = useCookies('tempNewProduct', defaultValues, defaultValues,{daysUntilExpiration: 7} );
  // const clientNewImage = useCookies('tempNewImage', {image:null}, {image:null},{daysUntilExpiration: 7} );
  const [customFields, setCustomFields] = useState([] );
  const { handleSubmit, control, watch,getValues,reset,setValue,formState:{errors} } = useForm({mode:'onChange', defaultValues: {...newItemData},values: {...clientNewItem.state}});
  const [dragImage, setDragImage] = useState(undefined);
  const [stockCount, setStockCount] = useState(0);
  const {serverIP} = useAuth();
  const showAlert = useBoolean(false);
  const showDialog = useBoolean(false);
  const scanBarcode = useBoolean(false);
  const isLoading = useBoolean(false);
  const dialogMessage = useRef({
    title: undefined,
    message: undefined
  })
  const descriptionLength = watch('description')?.length;


  useEffect(()=>{
      if (saveData){
        const data = getValues()
        const stateToStore = {...data};
        sessionStorage.setItem('tempNewImage', dragImage);
        clientNewItem.setState(stateToStore);
        // console.log('Stored data: ', stateToStore, dragImage);
      }
  },[saveData])

  useEffect(()=>{
    if (!saveData){
      
      // if (clientNewItem && clientNewItem.state?.customFields){
      //   setCustomFields(clientNewItem.state?.customFields)
      // }
      const tempImage = sessionStorage.getItem('tempNewImage')
      setTimeout(()=>{
        if (tempImage!=='null' && tempImage !== 'undefined' && tempImage )
        setDragImage(tempImage);
      },0*1e3 )
      // console.log('Stored item: ', clientNewItem.state, tempImage);
    }
  },[clientNewItem.state, saveData])

  // Handle Form Submit
  const onSubmit = (data) => {
     isLoading.onTrue();
     const customAttr = customFields.filter(item=>((Boolean(item.name)) && (Boolean(item.value))));
     let additionalItems = {};
     let index = 1;
     for (let item of customAttr){
      additionalItems[`modify${index}`] = {
          value: item.value,
          name: item.name
      }
      index +=1;
      }
      const finalData = { ...data, ...additionalItems, ...(dragImage && {image: dragImage}), quantity: stockCount }
      for (let item in finalData){
        if (!Boolean(finalData[item]) && !isNaN(finalData[item])){
          delete finalData[item]
        }
    }
   
    console.log('New Item:', finalData);
    axios.post(serverIP + '/create_product', finalData).then(res=>{
        if (res.status === 200){
          showDialog.onTrue();
          isLoading.onFalse();
        }
        else{
            dialogMessage.current = {
              title: 'בעיה בביצוע הפעולה',
              message: res.data.message
            }
            showDialog.onTrue();
            isLoading.onFalse();
        }
        // console.log('Server: ', res.data)
        clientNewItem.resetState();
        sessionStorage.clear('tempNewImage');
      }
    ).catch(err =>{
      // console.error('Error Adding new Item: ', err?.response?.data?.error);
      dialogMessage.current = {
        title: 'בעיה בביצוע הפעולה',
        message: err?.response?.data?.error || 'יש לנסות שוב במועד אחד'
      }
      showDialog.onTrue();
      isLoading.onFalse()
    })
}

  const handleReset = ()=>{
    reset();
    setCustomFields([]);
    clientNewItem.resetState();
    sessionStorage.clear('tempNewImage');
    setStockCount(0);
    setDragImage(null);
    showAlert.onFalse();
    isLoading.onFalse();
  }

  // Add Custom Field
  const addCustomField = () => {
    if (customFields.length < MAX_CUSTOM_FIELD) {
      setCustomFields([...customFields, { name: null, value: null }]);
    }
  };

  const sell_currencyFlag = currencies.filter(item=>item.value === watch('sell_currency'))[0]?.flag;
  const sell_coutryAvatar = <NoSsr fallback={<></>}>
                       <Avatar sx={{width:25, height:25, objectFit:'cover'}} src={sell_currencyFlag} />
                     </NoSsr>
  const buy_currencyFlag = currencies.filter(item=>item.value === watch('buy_currency'))[0]?.flag;
  const buy_coutryAvatar = <NoSsr fallback={<></>}>
                       <Avatar sx={{width:25, height:25, objectFit:'cover'}} src={buy_currencyFlag} />
                     </NoSsr>
  // Handle File Drop
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/webp": [],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        handleImageUpload(file)
        // const reader = new FileReader();
        // reader.onload = () => {
          
        //   setDragImage(reader.result);
        //   console.log('This is image res: ', reader.result, reader.LOADING, reader)
        // };
        // reader.readAsDataURL(file);
      } else {
        console.error("Invalid file type");
      }
    },
  });


  // Function to format the number with commas
  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // Function to remove commas for parsing
  const removeCommas = (formattedNumber) => {
    return formattedNumber.replace(/,/g, '');
  };

  
  const handleImageUpload = async (file, width=500, height=400) => {
    if (!file) return;

    try {
      // Compress and resize image
      const options = {
        maxSizeMB: 1, 
        maxWidthOrHeight: Math.max(width, height), // Resize the longest dimension to 500px
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      const resizedImage = await cropAndResizeImage(compressedFile, width, height);
      // Convert the compressed image to a base64 format
      // const base64Image = await convertToBase64(resizedImage);
  
      // Update state if needed
      setDragImage(resizedImage);
    } catch (error) {
      console.error('Error compressing the image:', error);
    }
  };

  const cropAndResizeImage = (file, targetWidth, targetHeight) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Set canvas dimensions
          canvas.width = targetWidth;
          canvas.height = targetHeight;

          // Calculate cropping
          const aspectRatio = img.width / img.height;
          const targetRatio = targetWidth / targetHeight;
          let sourceWidth, sourceHeight, sourceX, sourceY;

          if (aspectRatio > targetRatio) {
            // Image is wider than target aspect ratio
            sourceHeight = img.height;
            sourceWidth = img.height * targetRatio;
            sourceX = (img.width - sourceWidth) / 2;
            sourceY = 0;
          } else {
            // Image is taller than target aspect ratio
            sourceWidth = img.width;
            sourceHeight = img.width / targetRatio;
            sourceX = 0;
            sourceY = (img.height - sourceHeight) / 2;
          }

          // Draw image onto canvas with cropping
          ctx.drawImage(
            img,
            sourceX,
            sourceY,
            sourceWidth,
            sourceHeight,
            0,
            0,
            targetWidth,
            targetHeight
          );

          // Convert the canvas to a base64 data URL
          const base64Image = canvas.toDataURL('image/webp', 0.8);
          resolve(base64Image);
        };
        img.onerror = (error) => reject(error);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Box
      component="form"
      width={1}
      onSubmit={handleSubmit(onSubmit)}
      className="p-4 pt-2 scrollDiv"
    >
      <AlertDialog 
      open={showDialog.value} 
      onClose={onClose}
      message={dialogMessage.current.message}
      title={dialogMessage.current.title} />
      <Typography variant='h4' gutterBottom className='text-center !mb-4'>
        הוספת פריט למלאי
      </Typography>

      <div className='flex max-sm:flex-col-reverse mt-8'>
        {/* Left Column */}
        <div className='flex-[2] flex flex-col justify-between gap-2'>
          <Controller
            name="sku"
            control={control}
            rules={{ required: 'שדה זה חובה' }}
            render={({ field, fieldState }) => (
              <>
              <TextField
                variant={variantName}
                size='small'
                sx={{direction:'ltr'}}
                {...field}
                // onBlur={e=>e.target.placeholder='מק"ט'}
                // onClick={e=>e.target.placeholder=''}
                // placeholder='מק"ט'
                label='* מק"ט'
                error={!!errors.sku}
                helperText={errors?.sku?.message}
                className="*newItemInput*"
                aria-describedby={`${field.name}-error`}
              />
              {/* {fieldState.error && <div id={`${field.name}-error`} className="text-red-500 text-xs">
                {fieldState.error?.message}
              </div>} */}
              </>
            )}
          />

      <Controller
            name="product_name"
            control={control}
            rules={{required:'יש לציין שם למוצר'}}
            render={({ field ,fieldState}) => (
              <TextField
                variant={variantName}
                size='small'
                {...field}
                // onBlur={e=>e.target.placeholder='שם המוצר'}
                // onClick={e=>e.target.placeholder=''}
                // placeholder="שם המוצר"
                label="שם המוצר *"
                error={fieldState?.error}
                helperText={fieldState?.error?.message}
                className="*newItemInput*"
              />
            )}
          />

          {/* <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <TextField
                variant={variantName}
                size='small'
                {...field}
                // onBlur={e=>e.target.placeholder='מזהה'}
                // onClick={e=>e.target.placeholder=''}
                // placeholder="מזהה"
                label="מזהה"
                className="*newItemInput*"
              />
            )}
          /> */}
          <Controller
            name="barcode"
            control={control}
            render={({ field }) => (
              <div className='flex w-full gap-4 items-center'>
              <TextField
                variant={variantName}
                size='small'
                {...field}
                // onBlur={e=>e.target.placeholder='מזהה'}
                // onClick={e=>e.target.placeholder=''}
                // placeholder="מזהה"
                label="ברקוד"
                fullWidth
                className="*newItemInput*"
              />
              <Icon 
                onClick={scanBarcode.onToggle}
                className='p-1 rounded-lg hover:bg-zinc-300/40 self-end hover:opacity-80 cursor-pointer w-fit' icon="mdi:qrcode-scan" width={30} />
              <ScanBarcode showCamera={scanBarcode.value} onDetect={(item)=> setValue('barcode',item?.barcode)} onClose={scanBarcode.onFalse} />
              </div>
            )}
          />
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <TextField
                variant={variantName}
                size='small'
                {...field}
                // onBlur={e=>e.target.placeholder='מזהה'}
                // onClick={e=>e.target.placeholder=''}
                // placeholder="מזהה"
                label="קטגוריה"
                className="*newItemInput*"
              />
            )}
          />
         
        {/* Buying price */}
        <div className='w-full flex gap-8 '>
          <div className='flex'>
            <Controller
              name="supplier_price"
              id='price-label'
              rules={{
                min: { value: 0, message: 'יש להזין מחיר שאינו שלילי' },
                pattern: {
                  value: /^\d+(\.\d{1,2})?$/,
                  message: "המחיר חייב להיות עד 2 נקודות עשרוניות",
                },
              }}
              control={control}
              render={({ field }) => (      
                <div dir='ltr' className=' flex gap-4 items-baseline'>
              
                    <TextField
                    variant={variantName}
                    // startAdornment={currencyDict[getValues().currency]}
                    size='small'
                    {...field}
                    // onBlur={e=>e.target.placeholder='מחיר'}
                    // onClick={e=>e.target.placeholder=''}
                    // placeholder="מחיר"
                    label=" מחיר ספק (קנייה)"
                    type='number'
                    inputMode='numeric'
                    error={!!errors.price}
                    helperText={errors?.price?.message || ' '}
                    // type='number'
                    min={0}
                    className="*newItemInput*"
                  />
                </div>
              )}
            />
          </div>
          {Boolean(Number(watch('supplier_price'))) && <div className='flex items-center'>
            <Controller
              name="buy_currency"
              rules={{required:'יש לבחור מטבע לקניית המוצר'}}
              control={control}
              render={({ field }) => (
                <div className='flex gap-4 mb-1 items-end'>
                  <Select
                    {...field}
                    size='small'
                    variant={variantName}
                    autoWidth
                    sx={{px:1, borderBottom:'1.5px'}}
                    defaultValue={1}
                  >
                    {currencies.map((option) => (
                      <MenuItem  title={option.label} sx={{mx:2}}  key={option.value} value={option.value}>
                        {option.symbol}
                      </MenuItem>
                    ))}
                  </Select>
                {buy_coutryAvatar}
                </div>
              )}
            />
          </div>}
        </div>

        {/* Selling price */}
        <div className='w-full flex gap-8 '>
          <div className='flex'>
            <Controller
              name="client_price"
              id='price-label'
              rules={{
                min: { value: 0, message: 'יש להזין מחיר שאינו שלילי' },
                pattern: {
                  value: /^\d+(\.\d{1,2})?$/,
                  message: "המחיר חייב להיות עד 2 נקודות עשרוניות",
                },
              }}
              control={control}
              render={({ field }) => (      
                <div dir='ltr' className=' flex gap-4 items-baseline'>
              
                    <TextField
                    variant={variantName}
                    // startAdornment={currencyDict[getValues().currency]}
                    size='small'
                    {...field}
                    // onBlur={e=>e.target.placeholder='מחיר'}
                    // onClick={e=>e.target.placeholder=''}
                    // placeholder="מחיר"
                    label="מחיר לקוח (מכירה)"
                    type='number'
                    inputMode='numeric'
                    error={!!errors.price}
                    helperText={errors?.price?.message || ' '}
                    // type='number'
                    min={0}
                    className="*newItemInput*"
                  />
                </div>
              )}
            />
          </div>
          {Boolean(Number(watch('client_price'))) &&  
          <div className='flex items-center'>
            <Controller
              name="sell_currency"
              rules={{required:'יש לבחור מטבע למכירת מוצר המוצר'}}
              control={control}
              render={({ field }) => (
                <div className='flex gap-4 mb-1 items-end'>
                  <Select
                    {...field}
                    size='small'
                    variant={variantName}
                    autoWidth
                    sx={{px:1, borderBottom:'1.5px'}}
                    defaultValue={1}
                  >
                    {currencies.map((option) => (
                      <MenuItem  title={option.label} sx={{mx:2}}  key={option.value} value={option.value}>
                        {option.symbol}
                      </MenuItem>
                    ))}
                  </Select>
                {sell_coutryAvatar}
                </div>
              )}
            />
          </div>}
        </div>
        
        </div>
        {/* Right Column */}
        <div className='flex-1 justify-center p-2 flex-col gap-4'>
          {/* Drag and Drop */}
          <Paper
            {...getRootProps()}
            sx={{ boxShadow: 'none', position:'relative' }}
            className={`!p-1 !border-[1.5px] h-24 aspect-video mx-auto text-sm !border-dashed rounded-lg text-center ${isDragAccept ? 'border-red-800 ' : 'border-gray-300/60'}`}
          >
       
            <input {...getInputProps()} />
            {dragImage ? (
              <div className=''>
                <CloseIcon 
                top={-10}
                // left={0}
                right={'95%'}
                onClick={(e)=>{setDragImage();sessionStorage.clear('tempNewImage');e.stopPropagation();}}
                size={15} className={'z-40 hover:opacity-70 absolute'} title='מחיקת תמונה' />
                  <img
                    src={dragImage}
                    alt="Preview"
                    className="w-full relative -top-[2px] h-[90px] aspect-video object-cover rounded-lg"
                  />
              </div>
            ) : (
              <div className='flex flex-col justify-center'>
                <Icon icon="mdi:drag" fontSize="40px" className=" mx-auto" />
                <Typography sx={{ fontSize: 10 }}>
                  גרור תמונה לכאן או לחץ להעלאה
                </Typography>
              </div>
            )}
          </Paper>

          {/* Take Picture */}
          <div className='center mt-2'>
            <Tooltip arrow title='הוספת תמונה'>
              <IconButton
                aria-label="add image"
                sx={{boxShadow:2, p:0, '&:hover':{boxShadow:4}}}
                onClick={() => {
                  const cameraInput = document.createElement('input');
                  cameraInput.type = 'file';
                  cameraInput.accept = 'image/*';
                  cameraInput.capture = 'environment';
                  cameraInput.click();
                  cameraInput.onchange = (event) => {
                    const file = event.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = () => setDragImage(reader.result);
                    reader.readAsDataURL(file);
                  }
                };
              }}
            >
              <Icon className={buttonClass} width={40} icon="mdi:camera" />
            </IconButton>
          </Tooltip>
         </div>
      </div>
      </div>

      <div className='w-full flex flex-col my-2 gap-4'>
        <Controller
          name="description"
          control={control}
          rules={{
            maxLength: {value:80, message:'יש להזין עד 80 תוים'},
          }}
          render={({ field, fieldState }) => (
          <div className='w-full max-w-[700px]'>
          <Tooltip title='תיאור מוצר'>
              <TextField
                variant={variantName}
                size='small'
                {...field}
                label="תיאור מוצר"
                error={!!fieldState.error}
                helperText={fieldState.error ? <div className='text-red-600'>{fieldState.error?.message}</div> : `(${descriptionLength} מתוך 80)`}
                // style={fieldState.error && {background: varAlpha(red[200],0.15)}}
                className="*newItemInput* w-full"
              />
            </Tooltip>
            {/* {fieldState.error ? 
            <div id={`${field.name}-error`} className="text-red-500 text-xs">
                  {fieldState.error?.message}
                </div> : <div className='text-green-500 text-end text-xs'></div>} */}
            </div>
          )}
        />
        {/* Add Custom Fields */}
        {customFields.map((field, index) => (
          <Box component='div' key={index} className="my-2 items-end gap-4 max-w-full flex w-full">
            <TextField
              variant={variantName}
              size='small'
              // onBlur={e=>e.target.placeholder='שם השדה'}
              // onClick={e=>e.target.placeholder=''}
              // placeholder="שם השדה"
              label="שם"
              value={field.name}
              onChange={(e) =>
                setCustomFields((prev) =>
                  prev.map((f, i) =>
                    i === index ? { ...f, name: e.target.value } : f
                  )
                )
              }
              
                  className="*newItemInput* w-[40%] max-w-[300px]"
            />
            :
            <TextField
              variant={variantName}
              size='small'
              // onBlur={e=>e.target.placeholder='ערך השדה'}
              // onClick={e=>e.target.placeholder=''}
              // placeholder="ערך השדה"
              label="ערך "
              value={field.value}
              onChange={(e) =>
                setCustomFields((prev) =>
                  prev.map((f, i) =>
                    i === index ? { ...f, value: e.target.value } : f
                  )
                )
              }
              
                  className="*newItemInput* w-[40%] max-w-[300px]"
            />
            <Tooltip arrow title='מחיקת שדה'>
              <IconButton
                onClick={() => setCustomFields(customFields.filter((item, i) => i !== index))}
                aria-label="delete">
                <Icon
                  className='hover:opacity-80 rounded-full transition-opacity cursor-pointer'
                  icon="weui:delete-filled"
                  width={20}
                  height={20}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </div>


      <div className='my-4 center'>
        <Tooltip arrow title='הוספת שדה'>
          <IconButton
            sx={{boxShadow:2, p:0, '&:hover':{boxShadow:4}}}
            color='primary'
            aria-label="add field"
            onClick={addCustomField}
          >
            <Icon className={buttonClass} icon="typcn:plus" width={40} height={40} />
          </IconButton>
        </Tooltip>
      </div>

        {/* StockCount*/}

      <div className='w-full  justify-center items-center flex gap-8'>
       
           <Icon 
           style={{opacity: stockCount=== MAX_STOCK_COUNT ? 0.2:''}}
           onClick={()=>{setStockCount(p=> p>=MAX_STOCK_COUNT ? MAX_STOCK_COUNT : p+1)}}
           className='p-1 rounded-lg hover:bg-zinc-300/40 hover:opacity-80 cursor-pointer' icon="basil:add-outline" width={34} height={34}/>
          <TextField
            variant={variantName}
            error={!!errors.stockCount}
            inputMode='decimal'
            slotProps={{formHelperText:{sx:{textAlign:'center'}}, htmlInput:{className:'text-center'}}}
            title="מספר שלם בין 0 - 100,000"
            // value={stockCount}
            name='stockCount'
            value={formatNumberWithCommas(stockCount)}
            helperText='כמות פריטים במלאי'
            onChange={(e) => {
                const valueWithoutCommas = removeCommas(e.target.value);
                const number = Math.ceil(Number(valueWithoutCommas));

                if (!isNaN(number) && number >= 0 && number <= MAX_STOCK_COUNT) {
                  setStockCount(number);
                } else if (valueWithoutCommas === '') {
                  // Handle empty input
                  setStockCount('');
                }
              }}
           
            // onChange={(e)=>{
            //   const number = Math.ceil(Number(e.target.value));
            //   if(!isNaN(number) && number >= 0 && number <=MAX_STOCK_COUNT){
            //     setStockCount(number);
            //   }
            // }}
            />
        <Icon 
        style={{opacity: stockCount=== 0 ? 0.2 : ''}}
        onClick={()=>{setStockCount(p=> p<=0 ? 0 : p-1)}}
        className='p-1 rounded-lg hover:bg-zinc-300/40 hover:opacity-80 cursor-pointer' icon="gg:remove-r" width={30} height={30} />
      </div>


    {/* Alert before reseting */}
    
      <Collapse  component='div' className='w-fit mx-auto' in={showAlert.value}>
          <Alert 
          sx={{px:0}}
          action={
          <div className='mr-4 flex gap-4 bottom-0'>
            <Tooltip title='אישור'>
              <IconButton onClick={handleReset}  size='small'>
                <Icon icon="mingcute:check-2-fill" width="24" height="24" />
              </IconButton>
            </Tooltip>
            <Tooltip title='ביטול'>
              <IconButton onClick={showAlert.onFalse}  size='small'>
                <Icon icon="mdi:cancel-bold" width="24" height="24" />
              </IconButton>
            </Tooltip>
          </div>}
          onClose={()=>{}} severity="warning">
            <AlertTitle sx={{mx:1}}>אזהרה</AlertTitle>
              פעולה זו תמחק את הנתונים שכבר הוזנו עבור פריט זה
          </Alert>
      </Collapse>
    {/* Submit Button */}
    <Box className="mt-6 flex gap-4 justify-center items-center ">   
      <button
        type='submit'
        className="w-[60%] py-2 mainButton"
      >
        {isLoading.value ? <CircularProgress size={20} /> : 'הוספת פריט'}
      </button>
      <Tooltip title='איפוס טופס'>
          <IconButton
          aria-label="reset form"
          onClick={showAlert.onToggle}
          sx={{boxShadow:2, p:0, '&:hover':{boxShadow:4}}}
          >
            <Icon className={buttonClass}  icon="ri:reset-right-fill" width={30} />
          </IconButton>
      </Tooltip>
     
    </Box>
  </Box>
);
}