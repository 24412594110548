import React, { Suspense, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useBoolean } from "../../../utils/hooks/use-boolean";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Divider } from "@mui/material";
import CustumSkeleton from "../../assets/Skeleton";
import { NewTransaction, tableDict, tableOrder } from "./dbTable/renderTable";



const ItemCard = ({ product, mode=2, showImage=true , showCard=true, showAllData}) => {
    const [transactionType, setTransaction] = useState(null);
    const showAll = useBoolean(showAllData);
    const isLoading = useBoolean(true);
    useEffect(()=>{
        if(product && !product.image){
            const randomId = Math.floor(Math.random() * 150);
            // product.image = product.image || `https://picsum.photos/id/${randomId}/200`
            // isLoading.onFalse();
        }
        if (product && !product.modifiers){
            product.modifiers = []
        }
        if (isLoading.value){
            setTimeout(()=>{
                isLoading.onFalse();
            }, 0.8*1e3)
        }
    },[product])

    const Transactions = ({className=''})=> <div className={`absolute w-full bottom-2 flex justify-around ${className}`}>
                        <Button 
                        onClick={()=>setTransaction(1)}
                        size="small" sx={{scale:0.7}} color="success"  variant="contained">
                        מכירה
                        </Button>
                        <Button 
                        onClick={()=>setTransaction(2)}
                        size="small" sx={{scale:0.7}} color="warning" variant="outlined">
                        קנייה
                        </Button>
                    </div>

    let item;

    const skeleton_1 = 
        <div className="w-full relative pb-8">
            <CustumSkeleton className="w-full h-[30%] aspect-video rounded-lg" /> 
            <div className="flex flex-col justify-start w-full h-1/2">
            <CustumSkeleton borderRadius={3} containerClassName='flex-1 flex mt-4 mb-2' width='80%' height={10} />
             
            <CustumSkeleton borderRadius={3} containerClassName='flex-[5] flex flex-col gap-2 my-2' count={5} className='' width='65%' height={8} /> 
            </div>
           <div className="w-full absolute bottom-0 flex justify-around">
            <CustumSkeleton borderRadius={8} className='' width={50} height={30} /> 
            <CustumSkeleton borderRadius={8} className='' width={50} height={30} /> 
           </div>
           
        </div>
    const skeleton_2 = 
        <div className="px-2 relative w-[280px] pb-8">
            <div className="flex mt-8">
                <CustumSkeleton borderRadius={3} count={3} containerClassName='flex-[5] flex flex-col gap-2' width='30%' height={10} />
                <CustumSkeleton  className=" h-20 w-20 flex-1 rounded-lg shadow-md" /> 
             </div>
            <div className="flex flex-col justify-start">
            <CustumSkeleton borderRadius={3} containerClassName='flex-[5] flex flex-col gap-2 my-2' count={5} className='' width='65%' height={8} /> 
            </div>
           <div className="w-full absolute bottom-0 flex justify-around">
            <CustumSkeleton borderRadius={8} className='' width={50} height={30} /> 
            <CustumSkeleton borderRadius={8} className='' width={50} height={30} /> 
           </div>
           
        </div>
    const skeleton_3 = 
    <div className="w-[350px] max-sm:w-[75vw] relative pb-8">
        <CustumSkeleton className="mx-auto h-[80px] w-[80px] rounded-full mt-4 mb-2 shadow-xl" /> 
        <div className="flex flex-col justify-center gap-2 mb-4 w-full">
            <CustumSkeleton borderRadius={3} containerClassName='flex  justify-center' height={10} />
            <CustumSkeleton borderRadius={3} containerClassName='flex  justify-center' height={10} />
            <CustumSkeleton borderRadius={3} containerClassName='flex  justify-center' height={10} />
         </div>
         <div className="w-full">
            <CustumSkeleton borderRadius={3} containerClassName='flex border-b-[0.7px] border-b-gray-300/20 gap-2 my-2' count={5} className='' width='65%' height={8} /> 
            <CustumSkeleton borderRadius={3} containerClassName='flex gap-2 my-2' count={5} className='' width='65%' height={8} /> 
        </div>
       <div className="w-full absolute bottom-0 flex justify-around">
        <CustumSkeleton borderRadius={8} className='' width={50} height={30} /> 
        <CustumSkeleton borderRadius={8} className='' width={50} height={30} /> 
       </div>
       
    </div>

    
    switch (mode){
        case 1:   // Default layout
            item =    
            (isLoading.value || !product) ? skeleton_1 :
                <div className="">
                   {showImage && product.image &&
                    <CardMedia
                        component="img"
                        className="object-cover w-full max-h-32 aspect-square rounded-lg"
                        image={product.image}
                        alt={product.name}
                    />}
                    <CardContent dir="auto" component='div' className="text-start !h-full flex flex-col justify-between" sx={{px:1}}>
                        {
                            tableOrder.map((item,index)=>(
                                (product[item] || item==='quantity' )&&(showAll.value ? true : index<5) && 
                                <div className="my-[2px]" key={index}>
                                    <div  className="w-full flex gap-8">
                                        <div className="flex gap-2 flex-wrap">
                                            <Typography sx={{fontWeight:'bold'}} variant="body2">
                                                {tableDict[item] || item}:
                                            </Typography>
                                            <Typography sx={{opacity:0.8}} variant="body2">
                                                {product[item]|| 0}
                                            </Typography>
                                        </div>
                                        <Icon icon="circum:edit" width={25} className="hidden hover:block" />
                                    </div>
                                    {index !==Object.keys(product).length-1 &&
                                    <Divider className="!border-zinc-500/10"  sx={{my:0, border:'0.2px dashed'}}/>}
                                </div>
                            ))
                        }
                        {
                            product.modifiers?.map((item,index)=>(
                                <div className="my-[2px]" key={index}>
                                    <div  className="w-full flex gap-8">
                                        <div className="flex gap-2 flex-wrap">
                                            <Typography sx={{fontWeight:'bold'}} variant="body2">
                                                {item.name}:
                                            </Typography>
                                            <Typography sx={{opacity:0.8}} variant="body2">
                                                {item.value}
                                            </Typography>
                                        </div>
                                        <Icon icon="circum:edit" width={25} className="hidden hover:block" />
                                    </div>
                                    {index !==Object.keys(product).length-1 &&
                                    <Divider className="!border-zinc-500/10"  sx={{my:0, border:'0.2px dashed'}}/>}
                                </div>
                            ))
                        }
                       
            
                    </CardContent>
                </div>
            break;
        case 2:  // Compressed layout
            item =    
            (isLoading.value || !product) ? skeleton_2 :
                <div className="w-full min-w-[300px]">
                   {showImage && product.image &&
                    <CardMedia
                        component="img"
                        className="object-cover max-w-20 max-h-20 float-end mx-4 mt-4 shadow-md aspect-square rounded-lg"
                        image={product.image}
                        alt={product.name}
                    />}
                    <CardContent dir="auto" component='div' className="text-start flex flex-col gap-2" sx={{px:1}}>
                        {
                            tableOrder.map((item,index)=>(
                                (product[item] || item==='quantity' )&&(showAll.value ? true : index<5) && 
                                <div key={index}>
                                    <div  className="flex gap-8">
                                        <div className="flex gap-2 flex-wrap">
                                            <Typography sx={{fontWeight:'bold'}} variant="body2">
                                                {tableDict[item] || item}:
                                            </Typography>
                                            <Typography  sx={{opacity:0.8}} variant="body2">
                                               {product[item]}
                                            </Typography>
                                        </div>
                                        <Icon icon="circum:edit" width={25} className="hidden hover:block" />
                                    </div>
                                    {index !==Object.keys(product).length-1 &&
                                    <Divider className="!border-zinc-500/10"  sx={{my:0, border:'0.2px dashed'}}/>}
                                </div>
                            ))
                        }
                        {
                            product.modifiers?.map((item,index)=>(
                                <div className="my-[2px]" key={index}>
                                    <div  className="w-full flex gap-8">
                                        <div className="flex gap-2 flex-wrap">
                                            <Typography sx={{fontWeight:'bold'}} variant="body2">
                                                {item.name}:
                                            </Typography>
                                            <Typography sx={{opacity:0.8}} variant="body2">
                                                {item.value}
                                            </Typography>
                                        </div>
                                        <Icon icon="circum:edit" width={25} className="hidden hover:block" />
                                    </div>
                                    {index !==Object.keys(product).length-1 &&
                                    <Divider className="!border-zinc-500/10"  sx={{my:0, border:'0.2px dashed'}}/>}
                                </div>
                            ))
                        }
            
                    </CardContent>
                </div>
            break
        case 3:  // Horizontal layout
            item =    
            (isLoading.value || !product) ? skeleton_3 :
                <div dir="auto" className="min-w-[300px]">
                    <div className=" w-fit mt-2 mx-auto">
                        {showImage && product.image &&
                            <CardMedia
                                component="img"
                                className="object-cover mx-auto rounded-full h-24 w-24 aspect-square shadow-lg"
                                image={product.image}
                                alt={product.name}
                            />}
                    </div>
                    <div className="justify-center mt-2 mx-2 flex gap-2 flex-wrap">
                        <Typography sx={{fontWeight:'bold'}} variant="body2">
                            {tableDict.sku || 'sku'}:
                        </Typography>
                        <Typography  sx={{opacity:0.8}} variant="body2">
                            {product.sku}
                        </Typography>
                    </div>
                    {(product?.quantity || product?.quantity === 0 ) &&
                        <div className="justify-center mt-2 mx-2 flex flex-wrap">
                        <Typography sx={{fontWeight:'bold'}} variant="body2">
                            {tableDict.quantity || 'quantity'}:
                        </Typography>
                        <Typography  sx={{opacity:0.8}} variant="body2">
                          &nbsp; {product?.quantity}
                        </Typography>
                       
                    </div>
                    }
                    {product.product_name &&
                        <div className=" justify-center mt-2 mx-2 flex  flex-wrap">
                        
                        <Typography  sx={{opacity:0.8}} variant="body2">
                            {product.product_name}
                        </Typography>
                       
                    </div>
                    }
                    {product.description &&
                        <div className="justify-start mt-2 mx-2 flex flex-wrap">
                        
                        <Typography  sx={{opacity:0.8}} variant="body2">
                            {product.description}
                        </Typography>
                       
                    </div>
                    }
                  
                    <CardContent dir="auto" component='div' className="text-center w-full justify-center flex flex-wrap gap-4" sx={{px:1}}>
                        {
                            tableOrder.map((item,index)=>(
                            (product[item])&&(!['sku','description','product_name','quantity'].includes(item))&&(showAll.value ? true : index<5) && 
                                <div className="border-r-[1px] pr-2 border-dashed border-zinc-500/15" key={index}>
                                    <div  className="w-full flex flex-col gap-1 flex-wrap">
                                        <Typography key={index}  sx={{fontWeight:'bold'}}  variant="body2">
                                            {tableDict[item] || item}
                                        </Typography>
                                        <Divider className="!border-zinc-500/20"  sx={{my:0, border:'0.2px solid'}}/>
                                        <Typography variant="body2" sx={{opacity:0.8}}>
                                             {product[item]}
                                        </Typography>

                                        <Icon icon="circum:edit" width={25} className="hidden hover:block" />
                                    </div>
                                 
                                </div>
                            ))
                        }
                        {
                            product.modifiers?.map((item,index)=>(
                               (Boolean(item.value) && Boolean(item.value)) &&
                                <div className="border-r-[1px] pr-2 border-dashed border-zinc-500/15" key={index}>
                                    <div  className="w-full flex flex-col gap-1 flex-wrap">
                                            <Typography sx={{fontWeight:'bold'}} variant="body2">
                                                {item.name}
                                            </Typography>
                                            <Divider className="!border-zinc-500/20"  sx={{my:0, border:'0.2px solid'}}/>
                                            <Typography sx={{opacity:0.8}} variant="body2">
                                                {item.value}
                                            </Typography>
                                        </div>
                                        <Icon icon="circum:edit" width={25} className="hidden hover:block" />
                                </div>
                            ))
                        }
            
                    </CardContent>
                </div>
            break
        default:
            break            
    }
    


  return (
    <Card component='div' 
    onClick={()=>console.log('Item : ', product)}
    className={showCard ? "h-full hover:bg-zinc-400/40 cursor-pointer dark:!bg-zinc-900 dark:hover:!from-zinc-800/40 dark:hover:!to-zinc-950 dark:hover:bg-gradient-to-b ":'h-full border-[1px] border-zinc-400/5 !bg-transparent !shadow-sm rounded-none'}
     sx={{ width: mode===1 ? {xs:1,sm: 1, md:220, lg:250} : 1, p:1, position:'relative', pb:4}}>
        <NewTransaction item={product} type={transactionType} onClose={(e)=>{e?.stopPropagation(); setTransaction(null)}} />
        {item}
       {!isLoading.value && <Transactions />}
    </Card>
  );
};

export default ItemCard;
