import Skeleton from "react-loading-skeleton";


function CustumSkeleton({...props}){

    return (
        <Skeleton inline baseColor='grey' highlightColor='lightgrey' {...props}  />
    );
}

export default CustumSkeleton;