import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import '../assets/chatBackgrounds.css';
import './chatWidget.css';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useThemeMode } from '../../App';
import { messageBubble, whatsappColors } from '../services/chatAI/Chat';

const TypingIndicator = () => (
    <div className="flex w-full justify-end px-2 gap-1 items-end">
        {[1, 2, 3].map((dot) => (
            <div
                key={dot}
                className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                style={{ 
                    animationDelay: `${dot * 0.2}s`,
                    animationDuration: '1s'
                }}
            />
        ))}
    </div>
);

const Message = ({ message, index, isLoading, mode }) => {
    if (isLoading && !message.isUser) {
        return (
            <motion.div
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="message w-full"
            >
                <TypingIndicator />
            </motion.div>
        );
    }

    const { className, style } = messageBubble(
        !message?.isUser ? 'left' : 'right',
        mode
    );

    return (
        <motion.div
            key={index}
            viewport={{once: true}}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="message w-full"
        >   
            <div className={`${className} !my-0 ${!message.isUser? 'float-left' : 'float-right'}`} style={style}>
                <span className='overflow-x-auto break-words'>     
                    {message.content?.replace('**', '')}
                </span> 
            </div>
        </motion.div>
    );
};

const ChatWidget = ({ initialMessages = [{isUser: false, content:'היי, איך אפשר לעזור?'}] }) => {
    const [messages, setMessages] = useState(initialMessages);
    const [loadingStates, setLoadingStates] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);
    const { mode } = useThemeMode();

    useEffect(() => {
        messages.forEach((message, index) => {
            if (!message.isUser && !loadingStates[index]) {
                setLoadingStates(prev => ({ ...prev, [index]: true }));
                setTimeout(() => {
                    setLoadingStates(prev => ({ ...prev, [index]: false }));
                }, 3*1e3);
            }
        });
    }, [messages]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            if (newMessage?.includes('**')) {
                setMessages([...messages, { content: newMessage.replace('**',''), isUser: false }]);
            }
            else {
                 setMessages([...messages, { content: newMessage, isUser: true }]);
            }
            setNewMessage('');
        }
    };

    return (
        <div className="chat-widget ">
            {!isOpen ? (
                <motion.div
                    className="chat-icon"
                    onClick={() => setIsOpen(true)}
                    whileHover={{ scale: 1.1 }}
                >
                        <span className='flex gap-2'>
                        שאלו את ה AI
                        <Icon icon="mdi:chat" width="24" height="24" />
                        </span>
                </motion.div>
            ) : (
                <motion.div
                    className="chat-window"
                    transition={{ duration: 0.8 }}
                >
                    <div className="chat-header">
                        <h2 className="text-lg flex gap-2 font-semibold">
                        <img src={`${process.env.PUBLIC_URL}/assets/icon.png`}
                        width={30}
                         alt='Taskomatic logo' className="rounded-full" />
                         Taskomatic AI agent
                         </h2>
                        <button 
                            title='Minimize'
                            onClick={() => setIsOpen(false)}
                            className="text-white hover:text-gray-200"
                        >
                            <Icon className='p-1 hover:bg-zinc-300/20 rounded-full cursor-pointer' icon="mdi:minimize" width={25} />
                        </button>
                    </div>
                    <div 
                        style={{backgroundColor: whatsappColors[mode]?.background}} 
                        className="chat-body chat-background2"
                    >
                        {messages.map((message, index) => (
                            <Message 
                                key={index}
                                message={message} 
                                index={index}
                                isLoading={loadingStates[index] && messages.length === index+1}
                                mode={mode}
                            />
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="chat-footer">
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                            placeholder="כתוב לי הודעה..."
                            className="chat-input"
                        />
                        <button onClick={handleSendMessage} className="send-button"> 
                            <Icon icon="majesticons:send-line" width="24" />
                        </button>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default ChatWidget;