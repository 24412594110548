import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Box, Typography, Divider, Stack, Select, MenuItem, Avatar, NoSsr, CircularProgress } from '@mui/material';
import { currencies, currencyDict, variantName } from '../newItem/NewItem';
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import ItemsTable from '../dbTable/renderTable';
import { useBoolean } from '../../../../utils/hooks/use-boolean';
import { useAuth } from '../../../../App';
import AlertDialog from '../../../assets/dialogApp';
import { ScanBarcode } from '../../../assets/scanBarcode';

const paymentTypes = [
    { name: 'מזומן', value: 1 },
    { name: 'אשראי', value: 2 },
    { name: 'אפליקציה', value: 3 },
    { name: 'אחר', value: 4 },
  ];

const BuyForm = ({callBack=()=>{}, showBackArrow, item, onClose=()=>{}}) => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode:'onChange',
    defaultValues: {
      sku: '',
      barcode: '',
      quantity: 1,
      currency:1,
      price: '',
      client_name: '',
    },
  });
  const [quantity, setQuantity] = useState(1);
  const {serverIP} = useAuth();
  const [items, setItems] = useState(null);
  const [selectedItem, setSelected] = useState(null);
  const scanBarcode = useBoolean(false);
  const isLoading = useBoolean(false);
  const isLoadingServer = useBoolean(false);
  const showDialog = useBoolean(false);
  const dialogMessage = useRef({
    title:undefined,
    message:undefined
  })
  const [paymentType, setPaymentType] = useState(1);
  const sku = watch('sku');
  useEffect(()=>{
    if (selectedItem && selectedItem.sku !== sku){
      setSelected(null);
    }
    else if (!sku || selectedItem){
      return
    }
    isLoading.onTrue();
    setTimeout(()=>{
      isLoading.onFalse();
    },3*1e3)
    axios.post(serverIP +  '/search_products',{query: sku})
    .then(res=>{
      // setStock(res.data);

      if (res.status === 200){
        if (res.data?.data?.length > 4){
          setItems(res.data.data?.slice(0,3));
        }
        else{
          setItems(res.data.data);
        }
        isLoading.onFalse();
      }
      // console.log('This is server data: ', res.data)
    }).catch(err=> console.error('There was an error: ', err));
  },[sku])

  useEffect(()=>{
    setValue('quantity', quantity);
  },[quantity])

  useEffect(()=>{
    if (selectedItem){
      if (selectedItem?.sku){
        setValue('sku',selectedItem.sku)
      }
      if (selectedItem?.client_price){
        setValue('price',selectedItem.client_price)
      }
      if (selectedItem?.client_price){
        setValue('price',selectedItem.client_price)
      }
    }
    else{
      let tempSKU = sku;
      control._reset();
      setValue('sku', tempSKU);
    }
  },[selectedItem, selectedItem?.sku])

  useEffect(()=>{
    setSelected(item);
    if (item && item.barcode){
      setValue('barcode', item.barcode)
    }
  },[item])

  const onSubmit = (data) => {
    isLoadingServer.onTrue();
    const finalData = {...data, transaction_type:2, payment_method:paymentType};
    console.log('Form Data:', finalData );
    axios.post(serverIP + '/make_transaction', finalData).then(res=>{
      if (res.status === 200){
        showDialog.onTrue();
        isLoadingServer.onFalse();
        console.log('Server: ', res.data);
      }
    })
     .catch(res=>{
        dialogMessage.current = {
          title: 'הפעולה נדחתה',
          message: res.response?.data?.error|| 'משהו השתבש, נסו בפעם אחרת'
        }
        showDialog.onTrue();
        isLoadingServer.onFalse();
        console.log('Server: ', res)
      })
  };
  const handlePaymentMethodClick = (type)=>{
        setPaymentType(type)
    }

  const handleDetect = (item) =>{
      if (item && item.sku){
        setSelected(item);
      }
      else {
        setValue('barcode',item?.barcode);
      }
    }

  const price = Number(watch('price')).toFixed(2);
  const currency = watch('currency');
  let src;
  for (let item of currencies){
    if (item.value === currency ){
        src = item.flag;
    }
  }
  const currencyAvater = <NoSsr fallback={<></>}>
            <Avatar sx={{width:25, height:25, objectFit:'cover'}} src={src} />
          </NoSsr>

  return (
    <Box
      component="form"
      className='w-full p-2 h-full flex justify-start flex-col gap-4 max-w-2xl mx-auto'
      onSubmit={handleSubmit(onSubmit)}
    >
      <AlertDialog 
        open={showDialog.value} 
        onClose={onClose} 
        message={dialogMessage.current.message}
        title={dialogMessage.current.title}/>
  {showBackArrow &&    
    <Button 
    onClick={callBack}
    title='חזרה'
    variant='text'
    sx={{position:'absolute', top:'5%', right:8,boxShadow:0, borderRadius:20}}>
      <Icon icon="line-md:arrow-right" width={20} />
    </Button>}
      <Typography variant='h5' gutterBottom className='text-center !mb-4'>
        קנייה של מוצר
      </Typography>

      {/* SKU Field */}
      <Controller
        name="sku"
        control={control}
        rules={{ required: 'מק"ט שדה חובה' }}
        render={({ field }) => (
          <TextField
            {...field}
            label='מק"ט'
            sx={{direction:'ltr'}}
            variant={variantName}
            error={!!errors.sku}
            helperText={errors.sku ? errors.sku.message : ''}
            fullWidth
          />
        )}
      />
      {!selectedItem &&
       <div className='w-full flex justify-center'>
        {isLoading.value ? <CircularProgress size={20} /> : <ItemsTable onSelect={setSelected} click data={selectedItem ? [selectedItem] : items} />}  
      </div>}

      {/* Barcode Field */}
      <div className='w-full items-end flex gap-6'>
        <Controller
        name="barcode"
        control={control}
        // rules={{ required: 'ברקוד שדה חובה' }}
        render={({ field }) => (
          <TextField
            {...field}
            label='ברקוד'
            sx={{direction:'ltr'}}
            variant={variantName}
            error={!!errors.barcode}
            helperText={errors.barcode ? errors.barcode?.message : ''}
            fullWidth
          />
        )}
      />
      { !item?.barcode &&
      <>
        <ScanBarcode showCamera={scanBarcode.value} onDetect={handleDetect} onClose={scanBarcode.onFalse} />
        <Icon 
        onClick={scanBarcode.onToggle}
        className='p-1 rounded-lg hover:bg-zinc-300/40 hover:opacity-80 cursor-pointer' icon="mdi:qrcode-scan" width={30} height={30} />
      </>}
    </div>

      <div className='w-full items-center flex justify-between max-sm:flex-col max-sm:justify-center max-sm:gap-4'>
        {/* Quantity Field */}
        <Controller
            name="quantity"
            control={control}
            rules={{
            required: 'יש לציין כמות',
            valueAsNumber: true,
            min: { value: 0, message: 'לפחות 0 פריטים' },
            }}
            render={({ field , fieldState}) => (
                <div className='w-full flex max-sm:justify-center gap-4 mt-8'>
                        <Icon 
                            style={{opacity: quantity === 100000 ? 0.2:''}}
                            onClick={()=> {if (quantity+1 <= 100000) {setQuantity(p=> p+1)}}}
                            className='p-1 rounded-lg hover:bg-zinc-300/40 hover:opacity-80 cursor-pointer' icon="basil:add-outline" width={34} height={34}/>
                        <TextField
                            variant={variantName}
                            error={!!errors?.quantity}
                            inputMode='decimal'
                            // disabled={quantity>100000 || quantity<0}
                            slotProps={{formHelperText:{sx:{textAlign:'center'}}, htmlInput:{className:'text-center'}}}
                            // title="מספר שלם בין 0 - 100,000"
                            sx={{direction: 'ltr'}}
                            value={quantity}
                            onChange={(e)=>{if(!isNaN(e.target.value)&& Number(e.target.value)>=0) {setQuantity(e.target.value)}}}
                            helperText={fieldState.error?.message || `כמות פריטים לקנייה ${selectedItem && selectedItem?.quantity ? `(${selectedItem?.quantity} במלאי)`:''}`}
                            />
                        <Icon 
                            style={{opacity: quantity=== 0 ? 0.2 : ''}}
                            onClick={()=>{if (quantity-1 >=0){setQuantity(p=> p-1)}}}
                            className='p-1 rounded-lg hover:bg-zinc-300/40 hover:opacity-80 cursor-pointer' icon="gg:remove-r" width={30} height={30} />
                        
                </div>

            )}
        />

        {/* Sell Price Field */}
        <div className='flex w-fit items-baseline gap-4'>
        <Controller
            name="price"
            control={control}
            rules={{ required: 'נא לציין מחיר', valueAsNumber: true, min:{value:0, message:'המחיר חייב להיות גדול או שווה ל- 0'} }}
            render={({ field }) => (
            <div className='w-full justify-end items-baseline flex gap-2'>
                <TextField
                {...field}
                label="מחיר ליחידה"
                type="number"
                sx={{maxWidth:400, direction:''}}
                variant={variantName}
                error={!!errors.price}
                helperText={errors.price ? errors.price.message : ''}
                // fullWidth
            />
            {/* {currencyDict.ILS} */}
            </div>
            )}
        />
             <Controller
              name="currency"
              rules={{required:'יש לבחור מטבע לביצוע הפעולה'}}
              control={control}
              render={({ field }) => (
                <div className='flex gap-4 mb-1 items-end'>
                  <Select
                    {...field}
                    size='small'
                    variant={variantName}
                    autoWidth
                    sx={{px:1, borderBottom:'1.5px'}}
                    defaultValue={1}
                  >
                    {currencies.map((option) => (
                      <MenuItem  title={option.label} sx={{mx:2}}  key={option.value} value={option.value}>
                        {option.symbol}
                      </MenuItem>
                    ))}
                  </Select>
                {currencyAvater}
                </div>
              )}
            />

        </div>
      </div>
      {/* Client Name Field */}
      <Controller
        name="client_name"
        rules={{required:'יש לציין שם לקוח'}}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            dir='auto'
            label="שם ספק *"
            error={fieldState.error}
            helperText={fieldState?.error?.message}
            variant={variantName}
            fullWidth
          />
        )}
      />

        <Stack width={1} justifyContent='space-evenly' direction='row'>
            {paymentTypes.map((item)=>(
                <Button  
                key={item.name} 
                color='info'
                size='small'
                sx={{borderRadius:2, scale:0.8}}
                variant={paymentType === item.value ? 'contained':'outlined'} 
                onClick={() => handlePaymentMethodClick(item.value)}>{item.name}</Button>

            ))}
        </Stack>

      <Divider sx={{borderStyle:'dashed', mt:2}} />
    {  Boolean(price)&& Boolean(quantity) &&  
    <div className='w-full'>
            <Typography sx={{direction:'rtl'}} variant='body1'>
            {`סיכום:`}  {`${quantity} × ${price}`}
            </Typography>

            <Typography variant='body1'>
                סה"כ לתשלום: { (quantity * Number(price)).toFixed(2)}
            </Typography>
      </div>}

      {/* Submit Button */}
      <div className='w-full flex justify-center'>
        <Button 
        disabled={Boolean(!selectedItem)|| !Boolean(price) || !Boolean(quantity) }
        sx={{my:2, maxWidth:500}} type="submit" variant="contained" color='warning' fullWidth>
          {isLoadingServer.value ? <CircularProgress size={20} /> : 'ביצוע פעולה'}
        </Button>
      </div>
    </Box>
  );
};

export default BuyForm;
