import React, { useEffect, useState } from 'react';

import { useAuth } from '../App';
import ChatBot from './services/chatAI/ChatMain';
import Inventory from './services/InventoryAI/InventoryMain';
import { getMaskValue } from './assets/notifier/firbase';
import { useNavigate } from 'react-router-dom';
import PostomaticsMain from './services/postomatics/postomaticsMain';



function Home() {
  const {service, setService} = useAuth();
  const navigate = useNavigate();
  let res;

  
  useEffect(()=>{
    getMaskValue().then(res=>{
      if (res===999){
        navigate('/verify');
      }
    }).catch(e=>{});
    const recentService = localStorage.getItem('service');
    if (recentService){
      setService(recentService);
    }
    
  },[])


  switch (service){
    case 'ai':
      res = 
      <ChatBot />
      break
    case 'stock':  
      res = 
       <Inventory />
        break
    case 'management':
      res = 
      <Inventory />
      break
    case 'post':
      res = 
      <PostomaticsMain />
      break
    default: 
      res =  <Inventory />
      localStorage.clear('service');
      break


  }

  return (
    <div className='min-h-[80vh]'>
      {res}
    </div>
  );
}

export default Home;
