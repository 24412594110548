import { blueGrey, grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { pxToRem, responsiveFontSizes, setFont, varAlpha } from "../utils";
import shadows from "@mui/material/styles/shadows";

const [primaryFont, secondaryFont] = ['','rubik']

export const mainColor = '#1abc9c';
export const secondaryColor = '#d946ef';
export const borderRadius = '10px';

export const typography = {
  fontFamily: setFont(primaryFont),
  secondaryFont: secondaryFont,
  fontWeightLight: '300',
  fontWeightRegular: '400',
  fontWeightMedium: '500',
  fontWeightSemiBold: '600',
  fontWeightBold: '700',
  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 600,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  p: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'unset',
  },
  }

  export const breakpoints = {
    // Very small devices (older phones, feature phones)
    xs: 325,
  
    // Small devices (newer phones, like iPhone SE, older iPhone models)
    sm: 380,
  
    // Medium devices (most modern smartphones, like iPhone 14, Samsung Galaxy S23)
    md: 430,
  
    // Large devices (tablets, smaller laptops)
    lg: 780,
  
    // Extra large devices (large laptops, desktop monitors)
    xl: 1030,
  
    // Extra extra large devices (very large desktop monitors)
    xxl: 1450,
  };

const lightMode = {
  breakpoints:{
    values: breakpoints
  },
  palette: {
    mode: "light",
    primary: {
      main: mainColor, // Vibrant blue
      contrastText: "#ffffff",
    },
    secondary: {
      main: secondaryColor, // Bright orange
      contrastText: "#000000",
    },
    background: {
      default:'#ffffff',
      paper: "#ffffff", // Pure white for cards and papers
    },
    text: {
      primary: "#333333", // Dark grey for text
      secondary: "#555555", // Lighter grey for secondary text
    },
  },
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius, // Rounded corners
          padding: "10px 20px",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          ":hover": {
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // border:'1px solid',
          // borderColor: varAlpha(grey[300],0.4),
          borderRadius: "8px",
          padding: "8px",
          boxShadow: shadows[5],
          '&:hover':{
            backgroundImage: `linear-gradient(to top, ${varAlpha(grey[100],0.1)}, #fff)`
          }
        },
      },
    },
  },
};

const darkMode = {
  breakpoints:{
    values: breakpoints
  },
  palette: {
    mode: "dark",
    primary: {
      main: mainColor, // Soft blue
      contrastText: "#000000",
    },
    secondary: {
      main: secondaryColor, // Warm amber
      contrastText: "#000000",
    },
    background: {
      // default: "#000", // Dark grey
      default: "#000", // Dark grey
      paper: varAlpha(grey[900],0.5), // Slightly lighter dark grey
    },
    text: {
      primary: "#ffffff", // White text
      secondary: "#cccccc", // Greyish white for secondary text
    },
  },
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius,
          padding: "10px 20px",
          boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.1)",
          ":hover": {
            boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.2)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border:'1px solid',
          background: varAlpha(grey[900],1),
          borderColor: varAlpha(grey[800],0.4),
          borderRadius: "8px",
          padding: "8px",
          boxShadow: shadows[5],
          '&:hover':{
            backgroundImage: `linear-gradient(to top, ${varAlpha(grey[800],0.1)}, transparent)`
          }
        },
      },
    },
  },
};

export const getTheme = (mode) =>
  createTheme(mode === "light" ? lightMode : darkMode);
