
import { Icon } from '@iconify/react/dist/iconify.js';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton, Box } from '@mui/material';
import { format, set } from 'date-fns';
import { useEffect, useState } from 'react';
import { useBoolean } from '../../../utils/hooks/use-boolean';


export const PostsTable = ({ posts, onClick=()=>{}, filter=true }) => {
    const [expandedPost, setExpandedPost] = useState([]);
    const [expandedGroups, setExpandedGroups] = useState([]);
    const [sortedPosts, setSortedPosts] = useState([]);
    const isTimeSorted = useBoolean(false);
    useEffect(() => {
        setSortedPosts(posts);
    }, [posts]);

    const handleTimeSort = () => {
        if (!isTimeSorted.value){
            setSortedPosts([...posts].sort((a,b)=> new Date(a.start) - new Date(b.start)));
        } else {
            setSortedPosts(posts);
        }
        isTimeSorted.onToggle();
    };

    const handleExpandPost = (index) => {
        setExpandedPost(p=>[...p, index]);
    };

    const handleExpandGroups = (index) => {
        setExpandedGroups(p=>[...p, index]);
    };

    return (
        <Box sx={{p:0, mx:'auto',mb:8, mt:4, maxWidth:0.98}}>
            {filter && (
                <div className='flex w-full justify-start mx-4'>
                        <Tooltip title="מיין לפי זמן">
                            <IconButton 
                                sx={{justifySelf:'start', bgcolor: isTimeSorted.value ? 'lightgray' : 'transparent'}}
                                onClick={handleTimeSort}>
                                <Icon icon="bi:clock-history" className='text-xl' />
                            </IconButton>
                        </Tooltip>
                </div>
            )
            }
            <TableContainer dir='rtl'>
                <Table>
                    <TableHead>
                        <TableRow className='bg-gradient-to-r from-zinc-200 to-slate-100  dark:bg-gray-800 dark:from-transparent dark:bg-gradient-to-l dark:to-slate-900'>
                            <TableCell sx={{textAlign:'center'}} >פוסט</TableCell>
                            <TableCell sx={{textAlign:'center'}} >התחלה</TableCell>
                            <TableCell sx={{textAlign:'center'}} >קבוצות</TableCell>
                            <TableCell sx={{textAlign:'center'}} >חזרה (שעות)</TableCell>
                            <TableCell sx={{textAlign:'center'}} >כמות</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedPosts.map((post, index) => (
                            <TableRow 
                                sx={{...(isDatePast(post.start) && {backgroundColor: 'rgba(0,255,0,0.1)'})}}
                                onClick={()=>onClick(post, index)} 
                                className='hover:bg-zinc-100 dark:hover:bg-zinc-700 cursor-pointer' key={index}>
                                <TableCell className='text-wrap overflow-x-hidden w-fit max-w-xl' dir='auto'>
                                    {expandedPost.includes(index) ? post?.post : (
                                        <>
                                            {post.post.length > 60 ? `${post.post.substring(0, 60)}...` : post.post}
                                            {post.post.length > 60 && (
                                                <button className='mx-2 opacity-60 underline text-xs' onClick={(e) => {e.stopPropagation(); handleExpandPost(index)}}>
                                                    הצג הכל
                                                </button>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                                <TableCell>{format(new Date(post.start), 'PPpp')}</TableCell>
                                <TableCell  className='overflow-x-auto max-w-xl' dir='auto'>
                                    {expandedGroups.includes(index) ? post.groups.map((item,ind)=>(
                                        <span key={ind} className='text-nowrap text-xs mx-1 rounded-2xl p-1 px-2 border-[0.7px] border-zinc-500/40 bg-gray-200/40 dark:bg-gray-800/40'>
                                            {item?.groupname || item?.groupName}
                                        </span>
                                    )
                                    ) : (
                                        <>
                                            {post.groups.map((item,indx)=>(
                                                indx <2 &&
                                                <span key={indx} className='text-xs mx-1 rounded-2xl p-1 px-2 border-[0.7px] border-zinc-500/40 bg-gray-100/40 dark:bg-gray-800/40'>
                                                    {item?.groupname || item?.groupName}
                                                </span>))}
                                            {post.groups.length > 2 && (
                                                <button onClick={(e) =>{e.stopPropagation(); handleExpandGroups(index)}} className='mx-2 opacity-60 underline text-xs'>
                                                    {expandedGroups === index ? 'הצג פחות' : 'הצג הכל'}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                                <TableCell>{post.repeat || '-'}</TableCell>
                                <TableCell>{post.amount || '-'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export const isDatePast = (dateString) => {
    return new Date(dateString) < new Date();
}