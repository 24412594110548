import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogContent,
  Input,
  CircularProgress,
} from "@mui/material";
import '../../../../styles.css';
import BuyForm from "../transactions/buyForm";
import SellForm from "../transactions/sellForm";
import { CloseIcon } from "../InventoryMain";
import CustumSkeleton from "../../../assets/Skeleton";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useBoolean } from "../../../../utils/hooks/use-boolean";
import axios from "axios";
import { useAuth } from "../../../../App";

export const tableOrder = ['sku', 'product_name','quantity','category', 'client_price','color'];
export const tableDict = {
  sku: 'מק"ט',
  product_name: 'שם מוצר',
  description:'תיאור',
  supplier_price: 'מחיר ספק',
  client_price: 'מחיר',
  category: 'קטגוריה',
  color: 'צבע',
  transactions: 'פעולות',
  quantity: 'כמות במלאי',
  image: 'תמונה',
  id:'מזהה'
}

export const NewTransaction = ({type, item, onClose=()=>{}})=> 
  <Dialog 
    className="!bg-gray-300/5 relative"
    PaperProps={{className:'!bg-gradient-to-br from-white !rounded-xl to-gray-200/40 dark:from-darkGrey dark:via-darkGrey dark:to-zinc-950 !min-w-[60%] max-sm:!min-w-[95%] max-sm:!min-h-[95%] !min-h-[80vh]'}}
    fullWidth 
    // fullScreen={isMobile}
    open={Boolean(type)} 
    onClose={onClose}>

    <CloseIcon onClick={onClose} />
      <DialogContent sx={{px:0}} className="scrollDiv">
        {type === 2 ? <BuyForm onClose={onClose} item={item} /> : <SellForm onClose={onClose} item={item} />}
      </DialogContent>
  </Dialog>



const ItemsTable = ({ data, click, onSelect=()=>{}, 
showButtons, 
showImage, 
stockThreshold ,
onItemUpdate=()=>{}, 
editable, 
orderList = tableOrder }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [edit, setEdit] = useState({
    sku: null,
    column: null,
    new_value:''
  })
  const isEditing = useBoolean(false);
  const isLoading = useBoolean(false);
  const {serverIP} = useAuth();
  if (!data || data.length === 0) {
    return <p className="text-center text-gray-500">No data available</p>;
  }


  // Extract keys from the first item to use as column headers
  const keys = Object.keys(data[0]);

  const handleSelect = (sku, item)=>{
    console.log('Selected item: ', item);
    if (!click){
      return
    }
    if (selectedItem === sku){
      setSelectedItem(null, undefined);
      onSelect(null)
    }
    else{
      setSelectedItem(sku);
      console.log('Selected item: ', item)
      onSelect({...item})
    }
  }

  const handleClose = async (approve) =>{
      if(!approve){
          setEdit({
            column:null,
            new_value:''
          });
          isEditing.onFalse()
      }
      else if (approve){
        isLoading.onTrue();
        setTimeout(async()=>{
          const res = await axios.post(serverIP + '/update_product', edit);
          if (res.status === 200){
            onItemUpdate()
          }
          setEdit({sku:null, column:null, new_value:''});
          isLoading.onFalse();
          isEditing.onFalse();
        }, 1*1e3)
      }
  }

  const handleEditChange = (e)=>{
    if (['quantity','buy_currency','sell_currency','client_price','supplier_price'].includes(edit.column) || edit.column?.includes('price') || edit?.column?.includes('currency')){
      if (!isNaN(e.target.value)){
        setEdit(p=>({...p, new_value:e.target.value}))
      }
      return
    }
    setEdit(p=>({...p, new_value:e.target.value}))
  }

  const handleEdit = (item, column) =>{
      setEdit(p=>({
        new_value: item[column],
        sku: item.sku,
        column, 
      }))
      isEditing.onTrue();
  }

  return (
    <TableContainer component={Paper} sx={{boxShadow:0, borderRadius:4}} className="rounded-lg scrollDiv">
      <NewTransaction type={transaction?.type || false} item={transaction?.item} onClose={(e)=>{e.stopPropagation(); setTransaction(null)}} />
      <Table>
        {/* Table Head */}
        <TableHead >
          <TableRow >
              {showImage && 
              <TableCell
                className="bg-zinc-400/20 text-nowrap  font-bold text-sm uppercase">
                {tableDict['image'] || 'image'}
              </TableCell>}
            {orderList.map((key, index) => (
           (keys.includes(key)) &&  <TableCell
                key={index}
                className="bg-zinc-400/20 text-nowrap !text-center  font-bold text-sm uppercase"
              >
                {tableDict[key] || key}
              </TableCell>
            ))}
           {showButtons && 
            <TableCell
                className="bg-zinc-400/20 !text-justify text-nowrap font-bold text-sm uppercase"
            >
              {tableDict['transactions'] || 'transactions'}
            </TableCell>}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {data.map((item, rowIndex) => (
            <TableRow
              
              sx={{cursor: click ? 'pointer': ''}}
              onClick={()=>{handleSelect(item.sku, item)}}
              key={rowIndex}
              className={` hover:bg-zinc-400/10 ${selectedItem === item.sku ? 'bg-gray-500/20':''} dark:hover:bg-zinc-200/10 border-b border-slate-500/20 ${!isNaN(stockThreshold) && item.quantity <= stockThreshold && 'bg-red-200/20'}`}
            >
              { showImage && 
              <TableCell
                  className=" max-w-xs !text-center overflow-x-auto"
                >
                  {item.image ? <img 
                  className=" h-12 w-12 aspect-square rounded-lg shadow-lg"
                  // src={`https://picsum.photos/id/${(rowIndex*32)%140}/200`} 
                  src={item.image || `https://picsum.photos/id/${(rowIndex*32)%140}/200`} 

                  /> :
                   null
                  }
                </TableCell> }
              {orderList.map((key, colIndex) => (
                (keys.includes(key)) &&  
                <TableCell
                  key={colIndex}
                  sx={{textWrap: ['sku','user_sku', 'product_name', 'description'].includes(key) ? 'nowrap':''}}
                  className="text-gray-900 flex gap-2 items-center !text-center max-w-sm scrollDiv overflow-x-auto"
                >
                 {(Boolean(item[key]) || item[key] === 0) && <div className={`flex ${!isEditing.value && 'group'} gap-4 items-center`}>
                  {isEditing.value && edit.sku === item.sku && edit.column === key ?
                    <Input onKeyDown={(e)=>{if(e.key === 'Enter'){handleClose(true)}}} value={edit.new_value} onChange={handleEditChange} />
                  :
                    item[key]}
                   {isEditing.value && edit.sku === item.sku && edit.column === key ? 
                   <>
                    <Icon onClick={()=> handleClose(true)} className="p-1 rounded-lg cursor-pointer hover:bg-zinc-400/20" icon="material-symbols:check" width={25} />
                    <Icon onClick={()=> handleClose(false)} className="p-1 rounded-lg cursor-pointer hover:bg-zinc-400/20" icon="ix:cancel" width={25} />
                   </> : key !== 'sku' && editable &&
                   <Icon onClick={() => handleEdit(item, key )} className="opacity-40 hover:opacity-90 invisible group-hover:visible max-sm:visible cursor-pointer" icon="iconamoon:edit-thin" width={20}/>}
                   {isLoading.value && isEditing.value && edit.sku === item.sku && edit.column === key  && <CircularProgress size={15} />}
                  </div>}
                </TableCell> 
              ))}
              {showButtons && 
              <TableCell>
                <div className="flex justify-end gap-2">
                  <Button 
                  onClick={()=>setTransaction({type:1, item})}
                  size="small" sx={{scale:0.7}} color="success"  variant="contained">
                    מכירה
                  </Button>
                  <Button 
                  onClick={()=>setTransaction({type:2, item})}
                  size="small" sx={{scale:0.7}} color="warning" variant="outlined">
                    קנייה
                  </Button>
                </div>
              </TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ItemsTable;
