
export const gradients = [
    "bg-gradient-to-r from-sky-500 to-cyan-500",
    "bg-gradient-to-r from-indigo-500 to-purple-500",
    "bg-gradient-to-r from-emerald-500 to-teal-500",
    "bg-gradient-to-r from-yellow-500 to-amber-500",
    "bg-gradient-to-r from-orange-500 to-red-500",
    "bg-gradient-to-r from-pink-500 to-fuchsia-500",
    "bg-gradient-to-r from-blue-500 to-indigo-500",
    "bg-gradient-to-r from-green-500 to-teal-500",
    "bg-gradient-to-r from-yellow-500 to-amber-500",
    "bg-gradient-to-r from-purple-500 to-pink-500",
    "bg-gradient-to-br from-lime-200 to-yellow-200"
  ];

function HoverButton ({children, seed,buttonHover,...props}){
    if (Number.isNaN(seed)){
        seed = Math.floor(Math.random()*gradients.length);
    }
    else{
        seed = seed % gradients.length
    }
    return (
        <div
            {...props}
            className="group relative flex justify-center items-center text-sm font-bold">
            {/* <div class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-[150%] -translate-y-[300%] duration-500 group-hover:delay-500 skew-y-[20deg] group-hover:skew-y-0 shadow-md">
            </div> */}

            <div className={`shadow-md flex flex-row-reverse items-center group-hover:gap-2 ${gradients[seed]} p-4 rounded-full cursor-pointer duration-300`}>
                    {children}
                <span className="text-[0px] group-hover:text-sm duration-300">
                    {buttonHover}
                </span>
            </div>
        </div>

    );
}

export default HoverButton;