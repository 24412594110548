import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useBoolean } from '../../utils/hooks/use-boolean';

const defaultMessage = 'הפעולה בוצעה בהצלחה';
const defaultTitle = 'אישור פעולה';

export default function AlertDialog({open, message, title, onClose=()=>{}}) {
  const isOpen = useBoolean(open || false);

  React.useEffect(()=>{
    isOpen.setValue(open);
  },[open])

  const handleClose = (e) => {
    isOpen.onFalse();
    onClose(e);
  };

  return (
    <div className=''>
      <Dialog
        fullWidth
        open={isOpen.value}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title || defaultTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || defaultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>אישור</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}