import React, { useState, useEffect, createContext, useContext, useRef, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import axios from 'axios';
import Header from './components/Header';
import './styles.css';
import Home from './components/Home';
import Footer from './components/Footer';
import { getCookie, setCookie } from './utils/hooks/use-cookie';
import {  ThemeProvider, useColorScheme } from '@mui/material/styles';
import Reports from './components/services/chatAI/Reports';
import Settings from './components/services/chatAI/Settings';
import { getTheme } from './utils/theme/customTheme';
import { CssBaseline, useMediaQuery } from '@mui/material';
import PopUpMenu from './components/popUpMenu/popUpMenu';
import NotifierMessage from './components/assets/notifier/notifierDiv';

const AuthContext = createContext();
const ThemeContext = createContext();
const StockContext = createContext();

// פונקציה שאוספת מידע אם המשתמש מחובר
export function useAuth() {
  return useContext(AuthContext);
}

export function useStock(){
  const {stock, setStock} = useContext(StockContext);
  const getItem = (sku)=> stockData.stock?.filter(item=> item.sku === sku) || []

  return{
      stock,
      setStock,
      getItem
  }
}

export function useThemeMode() {
  const {mode, changeMode} = useContext(ThemeContext);

  return {mode, changeMode};
}

// פונקציה שאוספת את ההעדפה של המשתמש (רקע כהה  או בהיר)
function getUserColorScheme() {
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark"; // User prefers dark mode
  } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: light)").matches) {
    return "light"; // User prefers light mode
  }
  return undefined; // Default or no specific preference
}

// יצירת ערכת נושא לשימוש בכלים של MUI


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [service, setService] = useState(localStorage.getItem('service') || 'stock');
  const [collectionName, setCollectionName] = useState(undefined);
  const [userName, setUserName] = useState(undefined);
  const [stock, setStock] = useState([]);
  // const {setMode} = useColorScheme();
  const [mode, setColorMode] = useState(getUserColorScheme() || 'light');
  const theme = getTheme(mode);
  const isMobile = useMediaQuery(theme.breakpoints?.down('md'));
  const serverIP = useRef(process.env?.NODE_ENV === 'production' ? '' : 'http://localhost:5000');
  // בדיקת אימות כאשר האפליקציה נטענת
  useEffect(() => {;
    if (process.env.NODE_ENV === 'development'){
        const isAuth = sessionStorage.getItem('auth_token');
        if (isAuth){
          setIsAuthenticated(true);
          setUserName(isAuth);
        }
    }
    axios.get(serverIP.current + '/check_auth')
      .then(response => {
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
        }
      })
      .catch(error => {
        setIsAuthenticated(false);
      });
  }, []);

  // בדיקת מצב תצוגה כאשר האפליקציה נטענת
  useEffect(()=>{
    const storageMode = getCookie('themeMode');
    if (storageMode && storageMode === 'dark'){
      document?.body?.classList.add('dark');
      setCookie('themeMode', 'dark');
      setColorMode('dark');
      // setMode('dark');
    }
    else if (storageMode && storageMode === 'light'){
      document.body.classList.remove('dark');
      setCookie('themeMode', 'light');
      setColorMode('light');
      // setMode('light');
    }
    else {
      if (mode === 'dark'){
        document.body.classList.add('dark');
        setCookie('themeMode', 'dark');
      }
      else{
        document.body.classList.add('light');
        setCookie('themeMode', 'light');
      }
    }
  },[])

  const changeMode = ()=>{
    if (mode === 'dark'){
      const body = document.body;
      body.classList.remove('dark');
      setCookie('themeMode', 'light');
      setColorMode('light');
      // setMode('light');
    }
    else if (mode === 'light'){
      const body = document.body;
      body.classList.add('dark');
      setCookie('themeMode', 'dark');
      setColorMode('dark');
      // setMode('dark');
    }
    else{  //  Default value in case no Storage and no prefered colorScheme
      setColorMode('light');
      // setMode('light');
    }
  }

  const auth = useMemo(()=>({
    isAuthenticated,
    setIsAuthenticated,
    collectionName,
    setCollectionName,
    serverIP: serverIP.current,
    service,
    setService,
    isMobile
  })

  ,[ isAuthenticated,
    setIsAuthenticated,
    collectionName,
    setCollectionName,
    serverIP.current,
    service,
    isMobile,
    setService])

  return (
    <AuthContext.Provider value={auth}>
      <ThemeContext.Provider value={{mode, changeMode}}>
        <StockContext.Provider value={{stock, setStock}} >
          <CssBaseline>
            <ThemeProvider theme={theme}>
              <Router>
              
                <div className='min-h-screen relative'>
                {isAuthenticated && <>
                          <PopUpMenu />  
                          <Header userName={userName}  />
                        </>}
                  <div className="z-[0] dark:fixed hidden inset-0 bg-gradient-to-br from-main from-15% via-main/80 to-main/60 filter blur-[300px] ml-[-8vw] mt-[-30vh] w-[55vh] h-[55vh] rounded-[50%] "/>
                    <div className='mr-12 scrollDiv overflow-y-auto'>
                    {/* <div className='h-16' /> */}
                      <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/verify" element={<NotifierMessage />} />
                        {isAuthenticated ? (
                          <>
                            <Route path="/home" element={<Home />} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/settings" element={<Settings />} />
                          </>
                        ) : (
                          <Route path="*" element={<Navigate to="/login" />} />
                        )}
                      </Routes>
                    </div>
                  <Footer />
                </div>
              </Router>
              </ThemeProvider>
            </CssBaseline>
          </StockContext.Provider>
        </ThemeContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
