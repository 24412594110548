import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, TextField, Dialog, DialogActions, DialogContent, 
         DialogTitle, List, ListItem, ListItemText, IconButton, 
         Autocomplete, 
         Typography,
         Collapse} from '@mui/material';
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useBoolean } from '../../../utils/hooks/use-boolean';
import axios from 'axios';
import { useAuth } from '../../../App';
import { PostsTable } from './postsTable';
import GroupsTable from './groupsTable';
import { getMaskValue } from '../../assets/notifier/firbase';




const postsExample = [ 
    {
        post: 'Hello, World!',
        start: new Date(),
        repeat: 24,
        amount: 3,
        groups: ['AI enthusiasts']
    },
    {
        post: 'Goodbye, World!',
        start: new Date(),
        groups: ['AI enthusiasts', 'Chatbot developers']    
    }
];

const groupsExample = [
    {
        id: 1,
        groupId: '3824768',
        groupName: 'AI enthusiasts'
    },
    {
        id: 2,
        groupId: '123454',
        groupName: 'Chatbot developers'
    },
    {
        id: 3,
        groupId: 'aiagrifj',
        groupName: 'Clients for Automated Tools'
    },
]



const schema = yup.object().shape({
    post: yup.string()
        .required('תוכן הפוסט נדרש')
        .min(2, 'הפוסט חייב להיות לפחות 2 תווים')
        .max(500, 'הפוסט לא יכול לעלות על 500 תווים'),
    start: yup.date()
        .required('זמן התזמון נדרש')
        .min(new Date(), 'זמן התזמון חייב להיות בעתיד'),
    groups: yup.array()
        .of(yup.string())
        .min(1, 'נדרשת לפחות קבוצה אחת'),
        repeat: yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .min(3, 'העלאה חוזרת כל 3 שעות לפחות')
        .max(24*7, 'חזרה עד 7 ימים'),
    amount: yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .min(1, 'כמות פעמים להעלאה חייבת להיות לפחות 1')
        .max(10, 'הכמות לא יכולה לעלות על 10')
        
});

const PostomaticsMain = () => {
    const [posts, setPosts] = useState([]); 
    const [groups, setGroups] = useState([]);   
    const [open, setOpen] = useState(false);
    const isRepeat = useBoolean(false);
    const [editIndex, setEditIndex] = useState(null);
    const editMode = useBoolean(false);
    const loadPosts = useBoolean(false);
    const loadGroups = useBoolean(false);
    const {serverIP} = useAuth();
    const [page, setPage] = useState('posts');
    const groupOptions = groups.map(group => group.groupname || group.groupName);

    useEffect(()=>{
    getMaskValue().then(res=>{
        if (res>100){
        axios.post(serverIP+ '/logout')
        .then(() => {
            setIsAuthenticated(false);
            sessionStorage.clear();
            navigate('/login');
        })
        .catch(error => console.error(error));
        }
    }).catch(e=>{});
    },[])
    
    useEffect(()=>{
        axios.post(serverIP + '/getpost', { }).then((res) => {
            console.log('Server posts: ', res.data);
            setPosts(res.data);
        }).catch((error) => {
            console.error('Error: ', error.response?.data?.error);
            setPosts(postsExample);
        });
    },[loadPosts.value])

    useEffect(()=>{
        axios.get(serverIP + '/getgroups').then((res) => {
            console.log('Server groups: ', res.data);
            setGroups(res.data);
        }).catch((error) => {
            console.error('Error: ', error.response?.data?.error);
            setGroups(groupsExample);
        });
    },[loadGroups.value])

    const { register, handleSubmit, control,watch, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            post: '',
            start: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
            groups: [],
        },
        mode: 'onChange'
    });
    const post = watch('post'); 


    const handleOpen = (post = null, index = null) => {
        if (post) {
            editMode.onTrue();
            setEditIndex(index);
            if (post.repeat) isRepeat.onTrue();
            else isRepeat.onFalse();
            reset({
                post: post.post,
                start: format(new Date(post.start), "yyyy-MM-dd'T'HH:mm"),
                groups: post.groups.map(item=>(item.groupname || item.groupName)),
               ...(post.repeat && {repeat: post.repeat}),
                ...(post.amount && {amount: post.amount})
            });
        } else {
            editMode.onFalse();
            setEditIndex(null);
            reset({
                post: '',
                start: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
                groups: []
            });
        }
        setOpen(true);
    };

    const handleClose = () => {
        editMode.onFalse();
        setEditIndex(null);
        reset();
        setOpen(false);
    };

    const onSubmit = async (data) => {
        if (page === 'groups'){
            const formData = {
                groupName: data.target.elements['groupName'].value, 
                groupId: data.target.elements['groupId'].value
            };
            console.log('Sending new group to server: ', {data:[formData]});
            
            try{
                const serverRes = await axios.post(serverIP + '/setgroups', {data:[formData]});
                console.log('Server groups response: ', serverRes.data);
            } catch (error){
                console.error('Error: ', error.response?.data?.error);
            }
            loadGroups.onToggle();
        }
        else {
            if (editMode.value) {
                const updatedPosts = [...posts];
                updatedPosts[editIndex] = data;
                setPosts(updatedPosts);
                
            } else {
                setPosts([...posts, data]);
            }
            console.log('This is Post data : ', data);
            try{
                const serverRes = await axios.post(serverIP + '/setpost', data);
                console.log('Server posts response: ', serverRes.data);
            } catch (error){
                console.error('Error: ', error.response?.data?.error);
            }
            loadPosts.onToggle();   
      }
        handleClose();
    };

    const handleRemovePost = () => {
        if (!editIndex || !editMode.value) return; 
        setPosts(posts.filter((_, i) => i !== editIndex));
        // axios.post(serverIP + '/deletepost', post).then((res) => {
        //     console.log('Server response: ', res.data);
        // }).catch((error) => {
        //     console.error('Error: ', error.response?.data?.error);
        // });
        handleClose();
    };

    const postDialog = (   
    <Dialog dir='ltr' className='p-8 text-center' maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <Icon width={20} className='absolute cursor-pointer top-2 right-2 hover:opacity-80' onClick={handleClose} icon="zondicons:close-solid" />
        <DialogTitle>תזמון פוסט חדש </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <TextField
                    {...register('post')}
                    autoFocus
                    margin="dense"
                    label="פוסט"
                    name='post'
                    dir='auto'
                    multiline
                    rows={4}
                    fullWidth
                    style={{whiteSpace:'pre-wrap'}}
                    slotProps={{htmlInput:{dir:'auto'}}}
                    error={!!errors.post}
                    helperText={errors.post?.message || `נותרו ${500 -post?.length || 0} תווים`}
                    inputProps={{ style: { whiteSpace: 'pre-wrap' }, dir: 'auto' }}
                />

                <TextField
                    {...register('start')}
                    margin="dense"
                    label="התחלה"
                    type="datetime-local"
                    name='start'
                    fullWidth
                    error={!!errors.start}
                    helperText={errors.start?.message}
                />

                <Controller
                    name="groups"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            multiple
                            options={groupOptions}
                            onChange={(_, value) => field.onChange(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label="קבוצות"
                                    error={!!errors.groups}
                                    helperText={errors.groups?.message}
                                />
                            )}
                        />
                    )}
                />
            <div className='flex justify-end w-full'>
                    <button
                    className='text-main items-center flex mb-2 hover:opacity-80' 
                    onClick={(e)=>{e.preventDefault(); e.stopPropagation(); isRepeat.onToggle();}}
                    variant='text'>
                        {isRepeat.value ? 
                        <Icon width={20} icon="mdi:chevron-up" className='mr-2' /> :
                        <Icon width={20} icon="mdi:chevron-down" className='mr-2' />}
                         העלאה חוזרת
                    </button>
            </div>
            {isRepeat.value && 
                <Collapse in={isRepeat.value}>
                    <div className='flex flex-row-reverse gap-4 justify-between w-full'>
                        <Controller 
                            defaultValue={24}
                            name='repeat'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    margin="dense"
                                    label={`כל כמה זמן להעלות את הפוסט (כל ${field.value} שעות)`}
                                    type="number"
                                    name='repeat'
                                    className='w-1/2'
                                    fullWidth
                                    error={!!errors.repeat}
                                    helperText={errors.repeat?.message}
                                />
                            )} />
                
                        <Controller
                            defaultValue={1}
                            name='amount'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    margin="dense"
                                    label="כמות פעמים להעלאה חוזרת"
                                    type="number"
                                    name='amount'
                                    className='w-1/2'
                                    fullWidth
                                    error={!!errors.amount}
                                    helperText={errors.amount?.message}
                                />
                            )} />
                    </div>
                </Collapse>}
            </DialogContent>
            <DialogActions>
            <div className='flex justify-between w-full'>  
                <Button onClick={handleClose}>ביטול</Button>
                {editMode.value &&
                <IconButton title='מחיקת פוסט' sx={{px:1.5}} onClick={() => handleRemovePost(editIndex)}>
                    <Icon width={20} icon="mdi:delete" />
                </IconButton>}
                <Button type="submit" variant="contained">אישור</Button>
                </div>
            </DialogActions>
        </form>
    </Dialog>);

    const groupsDialog = (
        <Dialog dir='ltr' className='p-8 text-center' maxWidth="lg" fullWidth open={open} onClose={handleClose}>
            <Icon width={20} className='absolute cursor-pointer top-2 right-2 hover:opacity-80' onClick={handleClose} icon="zondicons:close-solid" />
            <DialogTitle>הוספת קבוצה חדשה</DialogTitle>
            <form onSubmit={(e)=>{e.preventDefault(); onSubmit(e)}}>
                <DialogContent>
                    <TextField                  
                        autoFocus
                        margin="dense"
                        label="שם הקבוצה"
                        name='groupName'
                        dir='auto'
                        fullWidth
                        error={!!errors.groupName}
                        helperText={errors.groupName?.message}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        label="מזהה הקבוצה"
                        name='groupId'
                        dir='auto'
                        fullWidth
                        error={!!errors.groupName}
                        helperText={errors.groupName?.message}
                    />
                </DialogContent>
                <DialogActions sx={{justifyContent:'space-between'}}>
                    <Button onClick={handleClose}>ביטול</Button>
                    <Button type="submit" variant="contained">אישור</Button>
                </DialogActions>
            </form>
        </Dialog>
    );

    const buttonOptions =
            <div className='flex justify-center gap-8 my-8'>
            <Button sx={{borderRadius:20}} className='w-fit rounded-full' variant="contained" color="primary" onClick={()=>handleOpen()}>
                <Icon icon="mdi:plus" className="ml-2" />
                {page === 'groups' ? 'קבוצה' : 'פוסט'}
            </Button>
            <Button className='w-fit rounded-3xl' variant={page === 'groups' ? 'contained':'outlined'} color="primary" onClick={()=>setPage('groups')}>
                <Icon icon="mdi:account-group" className="ml-2" />
                קבוצות
            </Button>
            <Button className='w-fit rounded-3xl' variant={page === 'posts' ? 'contained':'outlined'} color="primary" onClick={()=>setPage('posts')}>
                <Icon icon="iconoir:post" className="ml-2" />
                פוסטים
            </Button>
        </div>

    const titles = page === 'posts' ?
    <>
        {postDialog}
        <Typography component='div' variant='h6' gutterBottom className='mb-8 text-start px-2'>פוסטים מתוזמנים:</Typography>
    </> :
    <>
        {groupsDialog}
        <Typography component='div' variant='h6' gutterBottom className='mb-8 text-start px-2'>רשימת קבוצות:</Typography>
    </>

    return (
        <div dir='rtl' className="text-center pt-14 relative my-8 overflow-x-hidden">
            <h1 className="text-2xl font-bold mb-4">Postomatics Facebook</h1>
  
           {buttonOptions}
           {titles}         
           {page ==='posts' ? 
            <PostsTable onClick={handleOpen} posts={posts} />
            :
            <GroupsTable groups={groups} />
            }
   

        </div>
    );
};

export default PostomaticsMain;