import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Link } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

export default function NotifierMessage() {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '94vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: 10,
          m:'auto',
          borderRadius: 2,
          textAlign: 'center',
        }}
      >
        {/* <Typography variant="h1" color="error">
         היי, הפרופיל שלך מצריך אימות
        </Typography>
        <Typography variant="h4">צרו קשר עם מנהל האתר</Typography>
        <IconButton
          aria-label="WhatsApp"
          size="large"
          component={Link}
          href="https://wa.me/+972527242775"
          target="_blank"
          sx={{ color: 'green', my:10 }}
        >
         <Icon icon="logos:whatsapp-icon" width={100} />
        </IconButton> */}
      </Box>
    </Box>
  );
}