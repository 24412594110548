import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { Icon } from '@iconify/react/dist/iconify.js';
import Skeleton from 'react-loading-skeleton';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Popper, Tooltip } from '@mui/material';
import ToggleSwitch from '../../assets/toggleSwitch';
import { useAuth } from '../../../App';
import { useBoolean } from '../../../utils/hooks/use-boolean';

function Contacts({ onSelectNumber, autoReplyStatus, setAutoReplyStatus }) {
  const [numbers, setNumbers] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const stopSkeleton = useBoolean(false);
  const {serverIP} = useAuth();
  const currentNumber = useRef(null);

  useEffect(() => {
    loadContacts();
    setTimeout(()=>{
      stopSkeleton.onTrue();
    },12*1e3)
  }, []);

  const loadContacts = () => {
    stopSkeleton.onFalse();
    axios.get(serverIP+'/get_numbers')
      .then(response => {
        // console.log('Response data:', response.data); // בדיקה של הנתונים
        let initialStatus = {};
        response.data.forEach(number => {
          initialStatus[number.number] = number.auto_reply_status === 'ON';
        });
        setAutoReplyStatus(initialStatus);
        setNumbers(response.data);
        stopSkeleton.onTrue();
      })
      .catch(error => {
        stopSkeleton.onTrue();
        console.error(error)});
  };

  const toggleAutoReply = (number) => {
    const newStatus = !autoReplyStatus[number];
    axios.post(serverIP + '/update_auto_reply_status', {
      number: number,
      status: newStatus
    })
      .then(() => {
        // עדכן את הסטטוס רק אם הבקשה הצליחה
        setAutoReplyStatus(prevState => ({
          ...prevState,
          [number]: newStatus
        }));
      })
      .catch(error => {
        console.error(error);
        // הצג הודעת שגיאה למשתמש
        alert('עדכון הסטטוס נכשל. אנא נסה שוב.');
      });
  };

  const handleRefresh = async (number) => {
    console.log('Refreshing data...');
    loadContacts();
  }

  const Skeletons = <div className='flex h-12'>
                      <div className='flex-[4] mb-1'>
                        <Skeleton count={1} borderRadius={4} baseColor='grey' width='85%' className='rounded-main' highlightColor='lightgrey' height={13} />
                        <Skeleton count={1} borderRadius={4} baseColor='grey' width='60%' className='rounded-main' highlightColor='lightgrey' height={10} />
                        <Skeleton count={1} borderRadius={4} baseColor='grey' width='40%' className='rounded-main' highlightColor='lightgrey' height={8} />
                      </div>
                      <div className='flex-[1]'>
                        <Skeleton  baseColor='grey' width={45} height={18} className='rounded-full' highlightColor='lightgrey'  />
                      </div>
                    </div>

  const filteredNumbers =  numbers.filter(number =>
    String(number.number).includes(searchInput) ||
    (number?.last_time?.includes(searchInput)) || 
    (number.name?.toLowerCase()?.includes(searchInput?.toLocaleLowerCase()))
  );

  return (
    <div className='layout-right scrollDiv'>
      <div className='h-16'/>
      <div className='w-full my-4 flex justify-center'>
        <input
          type="text"
          placeholder="חיפוש..."
          className="block w-5/6 z-10 h-12 mainInput"
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
        />
      </div>
      <div className='flex justify-between'>
        <h3 className='text-lg* mr-3 mb-4 font-bold'>מספרים</h3>
        <h3 className='text-lg* mb-4 font-bold'>מענה ידני</h3>
      </div>
      <ul >
      {!filteredNumbers.length && !stopSkeleton.value && 
      <>
          {Array(10).fill(1).map((item, index)=>(
            <li key={index} className={`w-full h-20 block px-2 dark:shadow-gray-500/20 cursor-pointer m-2 border-b-[1px] pb-2 border-gray-500`}>
                  {Skeletons}
            </li>
          ))}
      </>
      }
        {filteredNumbers.map(number => (
          <NumberItem 
          key={number.number}
          refreshItem = {handleRefresh}
          toggleAutoReply={toggleAutoReply}
          onSelectNumber={onSelectNumber} 
          currentNumber = {currentNumber}
          autoReplyStatus={autoReplyStatus}
          number={number} 
          isSelected={currentNumber.current === number.number} />
        
        ))}
      </ul>
    </div>
  );
}

export default Contacts;


// פונקציה שטוענת את כל הנתונים שיש למספר נתון, כולל שם, טלפון, תאריך הודעה, שינוי השם וכפתור תשובה ידני
export const NumberItem = ({ 
  number={}, 
  isSelected, 
  onSelectNumber=()=>{},
  refreshItem=()=>{},
  currentNumber,
  toggleAutoReply=()=>{},
  hideButton,
  hideEditName,
  showNumOfMsg,
   autoReplyStatus}) => {
      const [name, setName] = useState(number.name || (number.number || ''));
      const {serverIP} = useAuth();
      const isEditName = useBoolean(false);
      const isFetching = useBoolean(false);

      const handleNameChange = async (event)=>{
        event?.preventDefault();
        event?.stopPropagation();
        isFetching.onTrue();
        try{
          const res = await axios.post(serverIP +'/update_name_by_number', {number:number.number, name});
          if (res.status === 200){
            refreshItem(number.number)
            isFetching.onFalse();
            isEditName.onFalse();
          }
        }
        catch (e){
          console.log('Name did not assigned properly', e);
          isFetching.onFalse();
          isEditName.onFalse();
        }
      }

      const handleItemClick = (e)=>{
        const isEditName = e.target.getAttribute('name') === 'editName';
        if (isEditName){
          e.stopPropagation();
          return
        }
        else {
        if (isSelected ){
          onSelectNumber(undefined)
          currentNumber.current = undefined;
          return;
        }
        currentNumber.current = number.number;
        onSelectNumber(number.number)}
        }
      
      

      const editName =   
              <Dialog PaperProps={{sx:{borderRadius:2,px:8, py:2, overflow:'visible'}}}  open={isEditName.value} onClose={isEditName.onFalse}>
                  <Icon 
                  onClick={(e)=>{e.stopPropagation(); isEditName.onFalse()}}
                  className='absolute p-1 rounded-full cursor-pointer hover:shadow-2xl hover:opacity-80 top-1 right-1'
                  icon="icon-park-solid:close-one" width="35" height="35" />
                 <form onSubmit={handleNameChange}>
                    <DialogTitle>עריכת שם עבור: <span dir='ltr'>{number.name || number.number}</span></DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        הזינו שם חדש:
                      </DialogContentText>
                      <input name='name' dir='auto' type='text' value={name} onChange={(e)=> setName(e.target.value)} className='mainInput my-4 w-full h-12'  />
                    </DialogContent>
                    <DialogActions>
                    <div className='w-full flex justify-center'>
                      <button onClick={(e)=>e.stopPropagation()} type='submit' className='mainButton flex items-center justify-center w-[80%]'>
                      שמירה
                      {isFetching.value && <Icon className='animate-spin mx-2' icon="tabler:loader-2" /> }
                      </button>
                    </div>
                    </DialogActions>
               </form>  
            </Dialog>

      const clientNumber = number.number?.includes('+') ? (number.number?.slice(0, 4) + '-' + number.number?.slice(4)) : number.number;


    return(
      <li
      key={number.number}
      className={`w-full block px-2 dark:shadow-gray-500/20 cursor-pointer m-2 border-b-[1px] pb-2 border-gray-500 ${isSelected ?'border-main shadow-sm':''}`}
      onClick={handleItemClick}
    >
     
        <div className=' flex w-full justify-between items-center flex-wrap'>
             <div className='flex gap-4'>
                <Tooltip arrow title={number.total_messages ? `סה"כ: ${number.total_messages} הודעות` : ''}>
                  <div style={{direction:'ltr'}} className='text-main flex flex-col'>
                    <div className='text-black/90 dark:text-white/90'>{number.name}</div>
                    <div>{clientNumber}</div>
                  </div>
                </Tooltip>
             {!hideEditName && <>
              <Tooltip arrow title={'עריכת שם'}>
                    <Icon 
                        className='opacity-60 relative top-2 transition-transform hover:scale-[1.15] hover:opacity-100 cursor-pointer'
                        onClick={(e)=>{e.stopPropagation();isEditName.onToggle()}} name='editName' icon="iconamoon:edit" width="15" height="15" />
               
                </Tooltip>
                </>}
              {editName}
             </div>
             
           {!hideButton && 
              <ToggleSwitch enabled={autoReplyStatus[number.number]} setEnabled={() => toggleAutoReply(number.number)} />}   
        </div>
        <div className='mt-1 w-full text-main items-end flex justify-between'>
          <Tooltip arrow title='הודעה אחרונה'>
            <span className='text-gray-500'>
              {number.last_time || 'לא זמין'}
            </span>
          </Tooltip>
          {showNumOfMsg && isSelected && number.total_messages && <p className='text-gray-600 dark:text-gray-300'>({number.total_messages})</p>}
          {isSelected && <Icon className='ml-[10px]' icon="ph:dot-fill" />}
        </div>
</li>)}
