

import {useState, useEffect}  from "react";
import Contacts from "./Contacts";
import Chat from "./Chat";
import Insights from "./Insights";


function ChatBot ({...props}){
  const [currentNumber, setCurrentNumber] = useState(undefined);
  const [autoReplyStatus, setAutoReplyStatus] = useState({});
  const handleSelectNumber = (number) => {
    setCurrentNumber(number);
  };

  const updateAutoReplyStatus = (status) => {
    setAutoReplyStatus(status);
  };


    return (
        <div className='layout-wrapper min-h-[95vh]'>
          <Contacts
            onSelectNumber={handleSelectNumber}
            autoReplyStatus={autoReplyStatus}
            setAutoReplyStatus={updateAutoReplyStatus}
          />
          <Chat currentNumber={currentNumber} 
            autoReplyStatus={autoReplyStatus} />
          <Insights currentNumber={currentNumber} />
    </div>
    );
}

export default ChatBot;