import { Icon } from '@iconify/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MainAnalysis({ sales, revenue, insights }) {
  const stats = [
      { id: 3, name: 'תובנות AI', stat: insights, icon: 'mdi:lightbulb-outline', change: '5%', changeType: 'increase', isData: false },
    { id: 1, name: 'מכירות', stat: sales, icon: 'mdi:shopping', change: '10%', changeType: 'increase',isData: true },
    { id: 2, name: 'הכנסה חודשית', stat: revenue, icon: 'mdi:currency-usd', change: '15%', changeType: 'increase',isData: true },
  ]

  return (
    <div className='my-4'>
      <h3 className="text-base font-semibold text-gray-900">30 הימים האחרונים</h3>
      <dl className="mt-5 grid  gap-4 divide-y  overflow-visible rounded-lg max-sm:!grid-cols-1 md:!grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.id} className="px-2 mx-2 py-5 rounded-lg  bg-gray-200/40 shadow-sm hover:scale-110 transition-transform dark:bg-gray-700/40 sm:p-6">
            <dt>
              <div className="absolute bg-mainColorGradient rounded-full p-3">
                <Icon icon={item.icon} className="text-white" width={24} aria-hidden="true" />
              </div>
              <p className="mr-16 text-md font-bold">{item.name}</p>
            </dt>
           <dd className="mr-16 pb-6 flex flex-row-reverse justify-end items-baseline sm:pb-7">
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-300">{item.stat}</p>
            {item.isData && <>
                <p className={classNames(
                    item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                    'ml-2 flex items-baseline text-sm font-semibold'
                )}>
                    {item.changeType === 'increase' ? (
                    <Icon icon="mdi:arrow-up" className="text-green-500" width={20} aria-hidden="true" />
                    ) : (
                    <Icon icon="mdi:arrow-down" className="text-red-500" width={20} aria-hidden="true" />
                    )}
                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} ב</span>
                    {item.change}
                </p>
              </>}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}