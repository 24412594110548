import { Icon } from "@iconify/react/dist/iconify.js";
import '../../components/services/InventoryAI/inventory.css';
import '../../styles.css';
import { Tooltip, Typography } from "@mui/material";
import { useBoolean } from "../../utils/hooks/use-boolean";
import { useAuth } from "../../App";
import { useEffect } from "react";

const iconClassName = (selected)=> 
`p-2 hover:bg-zinc-600/10 dark:hover:bg-zinc-100/10 opacity-100 cursor-pointer flex overflow-hidden gap-4 ${selected ? 'text-main':''}`;
// const iconsList = [
//     {
//         tooltipText: "מערכת צ'אט בוט",
//         icon:  'token:chat',
//         title: 'צ\'אט בוט'
//     },
//     {
//         tooltipText: "מערכת AI למלאי",
//         icon:  'token:inventory', // Replace with appropriate icon token
//         title: 'מערכת מלאי'
//     },
//     {
//         tooltipText: "מערכת ניהול AI",
//         icon: 'token:manage', // Replace with appropriate icon token
//         title: 'ניהול AI'
//     },
//     {
//         tooltipText: "מערכת יצירת חנות",
//         icon:  'token:shop', // Replace with appropriate icon token
//         title: 'יצירת חנות'
//     }
// ];

const iconsList = [
    {
        tooltipText: "מערכת צ'אט בוט",
        // icon: 'material-symbols:chat',
        icon: 'token:chat',
        title: 'צ\'אט בוט',
        service: 'ai',
    },
    {
        tooltipText: "מערכת AI למלאי",
        icon: 'material-symbols:inventory',
        title: 'מערכת מלאי',
        service: 'stock',
    },
    {
        tooltipText: "מערכת ניהול AI",
        icon: 'material-symbols:manage-accounts',
        title: 'ניהול AI',
        service: 'management',
    },
    {
        tooltipText: "פוסטים אוטומטיים",
        icon: 'mdi:facebook',
        title: 'Postomatics',
        service: 'post',
    },
    {
        tooltipText: "יצירת חנות דיגיטלית",
        icon: 'material-symbols:storefront',
        title: 'יצירת חנות',
        service: 'shop',
    },
];



function PopUpMenu ({...props}){
    const isDataOpen = useBoolean(false);
    const {service, setService} = useAuth();
    const redirectToWhatsApp = () => {
        const phoneNumber = '+972523440364';
        const url = `https://wa.me/${phoneNumber.replace('+', '')}`;
        window.open(url, '_blank', 'noopener,noreferrer');
      };
    
    useEffect(()=>{
        console.log('Changing service:',service);
        localStorage.setItem('service', service);
    },[service])    


    return (
        <div className={`fixed right-0 top-0 h-full w-12 overflow-hidden bg-gradient-to-br scrollDiv from-zinc-100 to-white dark:bg-gradient-to-br dark:from-zinc-900 dark:to-slate-800 z-20 transition-all  duration-500  border-l-[1px] border-zinc-500/15 shadow-xl ${isDataOpen.value ? 'openContainer':''}`}  >
        {/* {isDataOpen.value ? <div></div> : <p> תצוגה מהירה</p>} */}
        <Tooltip title='תפריט'>
                <Icon
                    className={`absolute cursor-pointer opacity-70 hover:opacity-100 left-0 top-16 ${!isDataOpen.value ? 'rotate-180':''} transition-transform duration-500 z-30 p-2 hover:opacity-100 rounded-lg`} 
                    onClick={isDataOpen.onToggle} icon="ic:twotone-double-arrow" width="50" height="50" />
        </Tooltip>
        <div className="absolute flex flex-col gap-8 top-32">
            {iconsList.map((item, index)=>(
                <div 
                key={index} 
                onClick={()=>setService(item.service)}
                className={iconClassName(service === item.service)}>
                    <Tooltip title={item.tooltipText}>
                        <Icon style={{opacity: service === item.service ? 1 : 0.8 }} icon={item.icon} width={30} />
                    </Tooltip>
                    <Typography noWrap variant="h6">
                            {item.title}
                    </Typography>
                </div>
            ))}
            <div 
                onClick={redirectToWhatsApp}
                className="p-2 hover:bg-zinc-600/10 dark:hover:bg-zinc-100/10 opacity-100 cursor-pointer flex overflow-hidden gap-4 "
                >
                    <Icon style={{ opacity: 1 }} icon="mdi:whatsapp" color="green" width={30} />
                    <Typography noWrap variant="h6">
                                תמיכה ושירות
                    </Typography>
                </div>
        </div>
        
         </div>
    );
}

export default PopUpMenu;