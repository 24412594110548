// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firebaseConfig } from "./config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Get a reference to the Firestore database
const db = getFirestore(app);



export async function updateOrCreateUser(userName, password) {
  let tableName = process.env?.NODE_ENV === 'development' ?'devUsers' : 'users';

  try {
    // Get a reference to the user document
    const userDocRef = doc(db, tableName, userName); // Modular syntax for getting a document reference
    const docSnapshot = await getDoc(userDocRef);
    if (docSnapshot.exists()) {
      // User document exists
      const userData = docSnapshot.data();

      // Check if the 'userName' field exists
      if (userData?.[userName]) {
        // Update the 'entry' field
        await updateDoc(userDocRef, {
          [`${userName}.entry`]: userData[userName].entry + 1,
        });
      } else {
        // Add a new field for the user
        await updateDoc(userDocRef, {
          [userName]: { entry: 1, userKey: password },
        });
      }
    } else {
      // Create a new user document
      await setDoc(userDocRef, {
        [userName]: { entry: 1 },
      });
    }
  } catch (error) {
    return
  }
}


/**
 * Searches for a document named 'mask' and retrieves the table name 'value'.
 * @returns {Promise<string|boolean>} The value of the 'value' field or false if not found.
 */
export async function getMaskValue(type='app') {
  try {
    // Reference to the document named 'mask' in the 'yourCollectionName' collection
    const maskDocRef = doc(db, "mask", type);
    const docSnapshot = await getDoc(maskDocRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      // Check if 'value' exists in the document
      if (data.hasOwnProperty("value")) {
        return data.value; // Return the value of 'value'
      }
    }

    return false;
  } catch (error) {
    // console.error("Error fetching document:", error);
    return false;
  }
}